import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const ViewScroll = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enable smooth scrolling
        });
    }, [pathname]);

    return null; // No UI to render
};

export default ViewScroll
