import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import notFoundImg from '../../assets/images/404.png'

const NotFound = () => {
    return (
        <>
            <Header />
                <div className="container">
                    <img src={notFoundImg} className='img-fluid mx-auto d-block' alt="" />
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <h1>404 - Page Not Found</h1>
                        <p>Sorry, the page you are looking for does not exist.</p>
                        <Link to="/">Go to Homepage</Link>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default NotFound
