import React from 'react'
import logo from '../../../assets/images/MindScape.png'


const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()
    return (
        <>
            <footer class="pt-5 pb-3">
                <div class="container">
                    <div class="row  justify-content-center text-center  align-items-center ">
                        <div class="col-12 col-md-12 col-xxl-6 px-0 ">
                            <a href="/"><img src={logo} width={150} alt="" /></a>
                        </div>
                    </div>
                    <hr />
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-6 col-12">
                            <span>
                                ©
                                <span id="copyright">
                                    {year} {' '}
                                </span>
                                MindScape Technologies, All Rights Reserved
                            </span>
                        </div>

                        <div class="col-12 col-md-6 col-lg-7 d-lg-flex justify-content-center">
                            <nav class="nav nav-footer">
                                <a class="nav-link ps-0" href="#">Privacy Policy</a>
                                <a class="nav-link px-2 px-md-0" href="#">Cookie Notice </a>
                                <a class="nav-link" href="#">Terms of Use</a>
                            </nav>
                        </div>
                        <div class="col-lg-2 col-md-12 col-12 d-lg-flex justify-content-end">
                            <div class="">

                                <a href="https://www.linkedin.com/company/mindscape-tech/" target='_blank' class="text-muted me-2">
                                    <i class="bi bi-linkedin fs-3"></i>
                                </a>

                                <a href="https://x.com/mindscapeindia" target='_blank' class="text-muted me-2">
                                    <i class="bi bi-twitter-x  fs-3"></i>
                                </a>

                                <a href="https://www.instagram.com/mindscapetechnologies.in" target='_blank' class="text-muted">
                                    <i class="bi bi-instagram fs-3"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
