import React, { useEffect, useState } from 'react'
import contactTable from '../../../Services/ContactServices';
import reviewFrom from '../../../Services/customerServices'
import leadServices from '../../../Services/LeadServices'


const Stats = () => {

    const [reviewNo, setReviewNo] = useState([])
    const [patner, setPatner] = useState([])

    useEffect(() => {
        const fatchData = async () => {
            const data = await reviewFrom.allReview()
            const response = (data.filter((check) => { return check.featured === "Partner" }))
            setPatner(response.length)
        }
        fatchData()
    }, [])

    useEffect(() => {
        const fatchData = async () => {
            const data = await reviewFrom.allReview()
            setReviewNo(data.length)
        }
        fatchData()
    }, [])

    const [contactNo, setContactNo] = useState([])
    useEffect(() => {
        const fatchData = async () => {
            const data = await contactTable.getContacts()

            setContactNo(data.length);
        }
        fatchData()
    }, [])

    const [lead, setLead] = useState()

    useEffect(() => {
        const fatchdata = async () => {
            const data = await leadServices.getTable()
            setLead(data.length)
        }
        fatchdata()
    },[])
    return (
        <>
            <div className="row">
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-primary text-dark-primary rounded-3"><i className="bi bi-person-lines-fill" ></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">{contactNo}</h2>
                            <p>Client Contacted</p>
                            <div className="progress bg-light-primary" style={{ height: '2px' }}>
                                <div className="progress-bar" role="progressbar" style={{ width: "65px" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-danger text-dark-danger rounded-3"><i className="bi bi-star-half"></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">{reviewNo}</h2>
                            <p>Customer Review's </p>
                            <div className="progress bg-light-danger" style={{ height: '2px' }}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '45%' }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-warning text-dark-warning rounded-3"><i className="bi bi-building-fill-check"></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">{patner}</h2>
                            <p>Our Patners </p>
                            <div className="progress bg-light-warning" style={{ height: '2px' }}>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "35%;" }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-primary text-dark-primary rounded-3"><i className="bi bi-backpack-fill" ></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">10</h2>
                            <p>Job Post's</p>
                            <div className="progress bg-light-primary" style={{ height: '2px' }}>
                                <div className="progress-bar" role="progressbar" style={{ width: "65px" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-danger text-dark-danger rounded-3"><i className="bi bi-people-fill"></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">145</h2>
                            <p>Application Received </p>
                            <div className="progress bg-light-danger" style={{ height: '2px' }}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '45%' }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="card card-hover mb-4  ">
                        <div className="p-4">
                            <span className="icon-shape icon-sm bg-light-danger text-dark-danger rounded-3"><i className="bi bi-diagram-2-fill"></i></span>
                            <h2 className="h1 fw-bold mb-0 mt-4 lh-1">{lead}</h2>
                            <p>Leads </p>
                            <div className="progress bg-light-danger" style={{ height: '2px' }}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '45%' }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stats
