import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Services from '../../../Services/adminServices'
import { ToastContainer, toast } from 'react-toastify';

const Admin = () => {

  const [AdminDetails, setAdminDetails] = useState([])

  useEffect(() => {
    const fatchData = async () => {
      const data = await Services.adminTable()
      //console.log('data', data);

      setAdminDetails(data)
    }
    fatchData()
  }, [])


  const handleDelete = async (id) => {
    const conform = window.confirm('Are You Sure Want To Delete Record')
    if (conform) {
      try {
        await Services.adminDelete(id)
        toast.success('Record Deleted Successfull')
        setAdminDetails(preAdmin => preAdmin.filter(admin => admin._id !== id));
      } catch (error) {
        toast.error('Failed to delete contact');
        console.error(error);
      }
    }
  }




  const [currentPage, setCurrentPage] = useState(1);
  const currentPerPage = 5;

  // Calculate index ranges
  const indexOfTheLastClient = currentPage * currentPerPage;
  const indexOfTheFirstClient = indexOfTheLastClient - currentPerPage;

  // Slice the array for current page clients
  const currentLeads = AdminDetails.slice(indexOfTheFirstClient, indexOfTheLastClient);

  // Calculate total pages
  const totalPage = Math.ceil(AdminDetails.length / currentPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="card card-hover">
        <div className="container py-8">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                  <h2 className="fw-bold">Admin</h2>
                  <p className="mb-0">All Admin Available Here</p>
                </div>
                <button type="button" className="btn btn-primary">Add New Admin</button>
              </div>

              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentLeads.map((l, index) => (
                      <tr key={l.id}>
                        <th scope='row'>{(currentPage - 1) * currentPerPage + index + 1}</th>
                        <td>{l.firstname}</td>
                        <td>{l.lastname}</td>
                        <td>{l.role}</td>
                        <td>{l.status}</td>
                        <td>
                          <Link to={`/admin/dashboard/admin/${l._id}`}><i className="bi bi-eye-fill text-primary me-2"></i></Link>
                          <a href="#!"><i className="bi bi-pen-fill text-primary me-2"></i></a>
                          <button className='btn' onClick={() => { handleDelete(l._id) }}><i className="bi bi-trash3-fill text-danger"></i></button>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>

              <nav aria-label="Page navigation">
                <ul className="pagination pagination-sm">
                  {Array.from({ length: totalPage }, (_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                      aria-current={currentPage === index + 1 ? 'page' : undefined}
                    >
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Admin
