import axios from 'axios';

const api_url = 'http://localhost:3001/api/customer-review/';

// Customer Review
const review = async (name, companyName, message, designation, rating, photo, featured, status) => {
    try {
        const formData = {
            name,
            companyName,
            message,
            designation,
            rating,
            photo,
            status,
            featured
        };

        // Log form data
        //  console.log('Form Data:', formData);

        const response = await axios.post(api_url + 'review', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data.success) {
            console.log('Form submitted successfully:', response.data);
            return response.data;
        } else {
            throw new Error(response.data.message || 'An error occurred while submitting the contact form');
        }
    } catch (error) {
        console.error('Error submitting contact:', error.response ? error.response.data : error.message);
        throw error;
    }
};

const allReview = async () => {
    try {
        const response = await axios.get(api_url + 'allreview')
        //console.log('check response', response);
        return response.data
    } catch (error) {
        console.error('Error getting contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}

const getReview = async (id) => {
    try {
        const response = await axios.get(`${api_url}review/${id}`)
        return response.data
    } catch (error) {
        console.error('Error getting contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}

const reviewDelete = async (id) => {
    try {
        const response = await axios.delete(`${api_url}review/${id}`)
        return response.data
    } catch (error) {
        console.error('Error getting contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}



const updateReview = async (id, formData) => {
    try {
        const response = await axios.post(`${api_url}update/${id}`,formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log('services response axios', response);

        return response.data
    } catch (error) {
        console.error('Error getting update contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}


export default {
    review, allReview, getReview, reviewDelete, updateReview
};
