import React, { useState } from 'react';
import logo from '../../../assets/images/avatar-1.jpg';
import { Link } from 'react-router-dom';

const Clients = () => {
    const ClientData = [
        { id: 1, companyName: 'Jonh Yec', companyLogo: logo, status: 'Pending' },
        { id: 2, companyName: 'Acme Corp', companyLogo: logo, status: 'Published' },
        { id: 3, companyName: 'Tech Innovations', companyLogo: logo, status: 'Rejected' },
        { id: 4, companyName: 'Bright Solutions', companyLogo: logo, status: 'Pending' },
        { id: 5, companyName: 'Global Ventures', companyLogo: logo, status: 'Published' },
        { id: 6, companyName: 'Creative Minds', companyLogo: logo, status: 'Rejected' },
        { id: 7, companyName: 'NextGen Industries', companyLogo: logo, status: 'Pending' },
        { id: 8, companyName: 'Pioneer Technologies', companyLogo: logo, status: 'Published' },
        { id: 9, companyName: 'Future Enterprises', companyLogo: logo, status: 'Rejected' },
        { id: 10, companyName: 'Vertex Systems', companyLogo: logo, status: 'Pending' }
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return 'green';
            case 'Pending':
                return 'orange';
            case 'Rejected':
                return 'red';
            default:
                return 'black';
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const currentPerPage = 5;

    // Calculate index ranges
    const indexOfTheLastClient = currentPage * currentPerPage;
    const indexOfTheFirstClient = indexOfTheLastClient - currentPerPage;

    // Slice the array for current page clients
    const currentClient = ClientData.slice(indexOfTheFirstClient, indexOfTheLastClient);

    // Calculate total pages
    const totalPage = Math.ceil(ClientData.length / currentPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="card card-hover">
                <div className="container py-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div>
                                    <h2 className="fw-bold">Clients</h2>
                                    <p className="mb-0">All Contact Reviews here</p>
                                </div>
                                <button type="button" className="btn btn-primary">Add Clients</button>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Comapny Name</th>
                                            <th scope="col">Company Logo</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentClient.map(c => (
                                            <tr key={c.id}>
                                                <th>{c.id}</th>
                                                <th>{c.companyName}</th>
                                                <td className='avatar-online'>
                                                    <img src={c.companyLogo} width={50} className='rounded-circle' alt="User Avatar" />
                                                </td>
                                                <td style={{ color: getStatusColor(c.status) }}>{c.status}</td>
                                                <td>
                                                    <Link to={`/admin/dashboard/clients/${c.id}`}><i className="bi bi-eye-fill text-primary me-2"></i></Link>
                                                    <a href="#!"><i className="bi bi-pen-fill text-primary me-2"></i></a>
                                                    <a href="#!"><i className="bi bi-trash3-fill text-primary"></i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul className="pagination pagination-sm">
                                    {Array.from({ length: totalPage }, (_, index) => (
                                        <li
                                            key={index + 1}
                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                            aria-current={currentPage === index + 1 ? 'page' : undefined}
                                        >
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Clients;
