import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css';

const WorkFlow = () => {
    return (
        <>
            <div className="container">
                <h2 className='h1 fw-bold mt-3'>Our Work Flow</h2>
                <OwlCarousel
                    className='owl-theme'
                    loop
                    margin={10}
                    nav
                    responsive={{
                        0: {
                            items: 1, // For mobile view, show 1 item
                        },
                        600: {
                            items: 2, // For medium devices, show 2 items
                        },
                        1000: {
                            items: 3, // For large devices, show 3 items
                        },
                    }}
                >
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-1-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Client Consultation</h3>

                        <div className="mt-5 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Understanding client needs.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Target audience.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Brainstorming sessions.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Gather requirements.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-2-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Project Planning</h3>

                        <div className="mt-5 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Outlining project scope.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Milestones.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Creating a sitemap.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Wireframes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-3-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Design & Prototype</h3>

                        <div className="mt-2 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Visual layout.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>User interface.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Prototypes.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Aesthetics.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-4-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Development & Coding</h3>

                        <div className="mt-2 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Functional websites.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Integrate code.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Build functionalities.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Ensure responsive.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-5-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Continuous Testing</h3>

                        <div className="mt-2 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Testing for bugs.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Compatibility check.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Cross-browser compatibility.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Performance optimization.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4">
                            <i className="bi bi-6-circle lh-1" style={{ fontSize: '50px' }}></i>
                        </div>
                        <h3 className="fw-bold">Post-Launch Support</h3>

                        <div className="mt-2 row">
                            <div className="col">
                                <ul className="list-unstyled fs-4 fw-medium">
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Deploying the Product.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Post-launch.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Ongoing support.</li>
                                    <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>User feedback.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </>
    );
};

export default WorkFlow;

