import React from 'react'
import Hero from '../Hero/Hero'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Services from '../Services/Services'
import Reaview from '../Review/Review'
import Clients from '../Clients/Clients'
import WorkFlow from '../WorkFlow/WorkFlow'
import Stats from '../Stats/Stats'

const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <Services />
            <Stats />
            <WorkFlow />
            <Clients />
            <Reaview />
            <Footer />
        </>
    )
}

export default Home
