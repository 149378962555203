import React from 'react'
import Achievements from '../../assets/images/Achievements.png'

const Stats = () => {
    return (
        <>
            <div className='py-lg-8 bg-light pt-8 pb-10'>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12 px-lg-10 mb-8 mt-6">
                            <span className="text-uppercase text-primary fw-semibold ls-md">Our Achievements
                            </span>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ">

                            <h1 class="display-4 fw-bold mb-3">Our global reach</h1>

                            <p class="lead">MindScape Technologies is the leading global marketplace, We see technology as a tool to create amazing things.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={Achievements} alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-lg-4 col-md-6 col-6">

                            <div class="border-top pt-4 mt-6 mb-5">
                                <h1 class="display-3 fw-bold mb-0">120+</h1>
                                <p class="text-uppercase text-muted">Family</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6">

                            <div class="border-top pt-4 mt-6 mb-5">
                                <h1 class="display-3 fw-bold mb-0">07+</h1>
                                <p class="text-uppercase text-muted">Products</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6">

                            <div class="border-top pt-4 mt-6 mb-5">
                                <h1 class="display-3 fw-bold mb-0">32+</h1>
                                <p class="text-uppercase text-muted">Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Stats
