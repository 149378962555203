import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/adminLogin.png';
import { Link, useParams } from 'react-router-dom';
import contactTable from '../../../Services/ContactServices';
import moment from 'moment'


const Customerform = () => {
    const { id } = useParams();
    const [contactDetail, setContactDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContactDetail = async () => {
            try {
                const data = await contactTable.contactDetail(id);
                setContactDetail(data.contact); // Adjust to get the contact data from the response
            } catch (error) {
                console.error('Error fetching contacts:', error.message);
                setError('Failed to fetch contact details.');
            } finally {
                setLoading(false);
            }
        };

        fetchContactDetail();
    }, [id]);

    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }

    if (!contactDetail) {
        return <h2>Contact not found!</h2>;
    }

    return (
        <div className="row">
            <div className='col-12 col-md-6'>
                <img src={logo} className='img-fluid' width={400} alt="Logo" />
            </div>
            <div className="col-12 col-md-6">
                <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="firstname">First Name</label>
                                <input type="text" id="firstname" name="firstname" value={contactDetail.firstname} className="form-control" placeholder="First Name" readOnly />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="lastname">Last Name</label>
                                <input type="text" id="lastname" value={contactDetail.lastname} name="lastname" className="form-control" placeholder="Last Name" readOnly />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" value={contactDetail.email} className="form-control" placeholder="Email" readOnly />
                            </div>
                            <div className="mb-3 col-12 col-md-6">
                                <label className="form-label" htmlFor="phone">Phone Number</label>
                                <input type="tel" id="phone" name="phone" value={contactDetail.phone} className="form-control" placeholder="Phone Number" readOnly />
                            </div>
                            <div className="mb-3 col-12">
                                <label className="form-label" htmlFor="message">Message</label>
                                <textarea id="message" name="message" className="form-control" rows="5" placeholder="Message" value={contactDetail.message} readOnly></textarea>
                            </div>
                            <div className="mb-3 col-12">
                                <label className="form-label" htmlFor="message">CreatedAt</label>
                                <input type="text" className='form-control' value={moment(contactDetail.createdAt).utc().format('llll')} />
                            </div>
                            <div className="col-12">
                                <Link to={'/admin/dashboard/contact-form'} className="btn btn-primary">Back</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customerform;
