import React from 'react';
import Stats from './Stats';


const Dashboard = () => {

    return (
        <>
            <Stats />
        </>
    );
}

export default Dashboard;
