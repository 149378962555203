import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import services from '../../../Services/LeadServices'
import { ToastContainer, toast } from 'react-toastify';


const Leads = () => {

    const [LeadDetail, setLeadDetails] = useState([])

    useEffect(() => {
        const fatchData = async () => {
            try {
                const data = await services.getTable()
                setLeadDetails(data)
            } catch (error) {
                console.log(error);
            }
        }
        fatchData()
    }, [])


    const handleDelelte = async (id) => {
        const conformation = window.confirm('Are you sure want to delete?')
        try {
            if (conformation) {
                await services.deleteLead(id)
                toast.success('Record Deleted Successfull')
                setLeadDetails(pre => pre.filter(lead => lead._id !== id))
            }
        } catch (error) {
            toast.error('Failed to delete lead');
            console.error(error);
        }

    }

    const [currentPage, setCurrentPage] = useState(1);
    const currentPerPage = 5;

    // Calculate index ranges
    const indexOfTheLastClient = currentPage * currentPerPage;
    const indexOfTheFirstClient = indexOfTheLastClient - currentPerPage;

    // Slice the array for current page clients
    const currentLeads = LeadDetail.slice(indexOfTheFirstClient, indexOfTheLastClient);

    // Calculate total pages
    const totalPage = Math.ceil(LeadDetail.length / currentPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="card card-hover">
                <div className="container py-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div>
                                    <h2 className="fw-bold">Clients Leads</h2>
                                    <p className="mb-0">All Clients Available Leads Here</p>
                                </div>
                                <Link to={'/admin/dashboard/create-lead'} className="btn btn-primary">Add New Lead</Link>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Comapny Name</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentLeads.map((l, index) => (
                                            <tr key={l.id}>
                                                <th scope='row'>{(currentPage - 1) * currentPerPage + index + 1}</th>
                                                <td>{l.firstname}</td>
                                                <td>{l.lastname}</td>
                                                <td>{l.companyname}</td>
                                                <td>{l.status}</td>
                                                <td>
                                                    <Link to={`/admin/dashboard/leads/${l._id}`}><i className="bi bi-eye-fill text-primary me-2"></i></Link>
                                                    <Link to={`/admin/dashboard/leads/update-lead/${l._id}`} ><i className="bi bi-pen-fill text-success me-2"></i></Link>
                                                    <button className='btn' onClick={() => { handleDelelte(l._id) }}><i className="bi bi-trash3-fill text-danger"></i></button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul className="pagination pagination-sm">
                                    {Array.from({ length: totalPage }, (_, index) => (
                                        <li
                                            key={index + 1}
                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                            aria-current={currentPage === index + 1 ? 'page' : undefined}
                                        >
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Leads
