import React from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
    return (
        <>
            <div className="py-lg-8 bg-light pt-8 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="row text-center">
                                <div className="col-md-12 px-lg-10 mb-8 mt-6">
                                    <span className="text-uppercase text-primary fw-semibold ls-md">Our Services
                                    </span>
                                    <h2 className="h1 fw-bold mt-3">How we can help you?
                                    </h2>
                                    <p className="mb-0 fs-4">Discover our tech education and innovative solutions. We empower your success in the digital world</p>
                                </div>
                            </div>
                            <div className="row gy-4">
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div className="card  border-top border-muted border-4  card-hover-with-icon">
                                        <div className="card-body">
                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3  card-icon">
                                                <i className="bi bi-browser-edge lh-1" style={{ fontSize: '24px' }}></i>

                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 className="mb-0">Web Development</h4>
                                                    <p className="mb-0 text-muted">Boost your online presence with cutting-edge web development solutions.</p>
                                                </div>
                                                <Link to={'/services/Web-development/'} className="text-inherit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div className="card  border-top border-muted border-4  card-hover-with-icon">
                                        <div className="card-body">
                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3  card-icon">
                                                <i className="bi bi-yelp lh-1" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 className="mb-0">UI / UX Design</h4>
                                                    <p className="mb-0 text-muted">Create unforgettable digital experiences with stunning UI/UX designs. Elevate your brand's online presence!</p>
                                                </div>
                                                <Link to={'/services/ui-ux/'} className="text-inherit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div className="card  border-top border-muted border-4  card-hover-with-icon">
                                        <div className="card-body">
                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3  card-icon">
                                                <i className="bi bi-bag-heart lh-1" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>


                                                    <h4 className="mb-0">Shopify Development</h4>


                                                    <p className="mb-0 text-muted">Elevate your Shopify store with our expert development services. Tailored solutions to boost your online presence!</p>
                                                </div>
                                                <Link to={'/service/shopify-development/'} className="text-inherit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">


                                    <div className="card  border-top border-muted border-4  card-hover-with-icon">


                                        <div className="card-body">


                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3  card-icon">
                                                <i className="bi bi-wordpress lh-1" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>


                                                    <h4 className="mb-0">WordPress Development</h4>


                                                    <p className="mb-0 text-muted">Unlock the full potential of WordPress with our expert development services. Elevate your online presence with customized solutions!</p>
                                                </div>


                                                <Link to={'/service/wordpress-development/'} className="text-inherit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div className="card  border-top border-muted border-4  card-hover-with-icon ">
                                        <div className="card-body">
                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3  card-icon">
                                                <i className="bi bi-android lh-1" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 className="mb-0">Mobile Developer
                                                    </h4>
                                                    <p className="mb-0 text-muted">Elevate your business with custom mobile apps. Seamlessly connect with your audience on the go!</p>
                                                </div>
                                                <Link to={'/services/App-development/'} className="text-inherit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div className="card  border-top border-muted border-4  card-hover-with-icon">
                                        <div className="card-body">
                                            <div className="icon-shape icon-lg rounded-circle bg-light text-muted mb-3 card-icon">
                                                <i className="bi bi-sliders lh-1" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 className="mb-0">Customization Service
                                                    </h4>
                                                    <p className="mb-0 text-muted">Custom-tailored services for your Envato products. Bring your vision to life with our expert touch!</p>
                                                </div>
                                                <div>
                                                    <Link to={'/services/customization-services/'} className="text-inherit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Services
