import axios from 'axios';


const api = 'http://localhost:3001/api/lead/'

const getTable = async () => {
    try {
        
        const response = await axios.get(api + 'table')
        // console.log(response);
        return response.data
    } catch (error) {
        console.error('Error getting leads table:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}

const getLead = async (id) => {
    try {
        const response = await axios.get(`${api}lead/${id}`)
        // console.log(response);
        return response.data
    } catch (error) {
        console.error('Error getting leads data:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}

const deleteLead = async (id) => {
    try {
        const response = await axios.delete(`${api}lead/${id}`)
        console.log(response);
        return response.data

    } catch (error) {
        console.error('Error deleting leads data:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}
const updateLead = async (id, leadData) => {
    try {
        const response = await axios.post(`${api}update/${id}`, leadData, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating leads data:', error.response ? error.response.data : error.message);
        throw error;
    }
};

const createLead = async (agent, city, companyname, email, field, firstname, lastname, leadDate, message, phone, source, status, subject) => {
    try {
        const formData = {
            agent, city, companyname, email, field, firstname, lastname, leadDate, message, phone, source, status, subject
        }
        const response = await axios.post(`${api}create`, formData, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating leads data:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export default {
    getTable, getLead, deleteLead, updateLead, createLead
}