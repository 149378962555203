import React, { useState } from 'react';
import loginImage from '../../../assets/images/adminLogin.png'; // Ensure this path is correct
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Login = ({ setAuth }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const [login, setLogin] = useState({
        username: '',
        password: '',
    });

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLogin({
            ...login,
            [name]: value,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setMessage('');
        try {
            const { username, password } = login;
            const api = 'http://localhost:3001/api/admin/login'; // Ensure the path is correct

            const response = await axios.post(api, { username, password });

            localStorage.setItem('token', response.data.token);
            setAuth(true);

            toast.success('Login successful');
            setTimeout(() => {
                navigate('/admin/dashboard');
            }, 2000);
        } catch (error) {
            const message = error.response?.data?.msg || 'Login failed. Please check your credentials and try again.';
            setMessage(message);
            setError(true);
            toast.error(message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <div className='py-8 container'>
                <div className="row">
                    <div className="col-md-6">
                        <img src={loginImage} width={300} alt="Login" />
                    </div>
                    <div className="col-md-6">
                        <div className="card mb-4">
                            <div className="card-header">
                                <h3 className="mb-0">Welcome Back Admin</h3>
                            </div>
                            <div className="card-body">
                                <form className="row" onSubmit={handleLogin}>
                                    <div className="mb-3 col-12">
                                        <label className="form-label" htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            className="form-control"
                                            placeholder="Username"
                                            onChange={handleInputChange}
                                            value={login.username}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3 col-12">
                                        <label className="form-label" htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Password"
                                            onChange={handleInputChange}
                                            value={login.password}
                                            required
                                        />
                                    </div>
                                    {message && (
                                        <div className="mb-3 col-12">
                                            <div className={`alert ${error ? 'alert-danger' : 'alert-success'}`} role="alert">
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                    <div className="mb-3 col-12">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    );
};

export default Login;
