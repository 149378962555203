import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import logo from '../../../assets/images/MindScape.png';
import avatar from '../../../assets/images/avatar-1.jpg';
import auth from '../../../Services/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DashHeader = () => {
    const navigator = useNavigate()

    const handleOnClick = () => {
        try {
            auth.logout();
            toast.success('Your LogOut Successfull')
            setTimeout(() => {
                navigator('/admin/login')
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid px-0">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} width={150} alt="MindScape Logo" className="" />
                    </Link>

                    <div className="ms-auto d-flex align-items-center order-lg-3">
                        <ul className="navbar-nav navbar-right-wrap mx-2 flex-row">
                            <li className="dropdown ms-2 d-inline-block position-static">
                                <a className="rounded-circle" href="#"
                                    data-bs-toggle="dropdown"
                                    data-bs-display="static" aria-expanded="false">
                                    <div className="avatar avatar-md avatar-indicators avatar-online">
                                        <img alt="avatar"
                                            src={avatar}
                                            className="rounded-circle" />
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end position-absolute mx-3 my-5">
                                    <div className="dropdown-item">
                                        <div className="d-flex">
                                            <div className="avatar avatar-md avatar-indicators avatar-online">
                                                <img alt="avatar"
                                                    src={avatar}
                                                    className="rounded-circle" />
                                            </div>
                                            <div className="ms-3 lh-1">
                                                <h5 className="mb-1">Saurabh Dhumane</h5>
                                                <p className="mb-0 text-muted">
                                                    Mindscapetechnologies.in
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to={'/admin/dashboard/change-password'} className="dropdown-item">
                                                <i className="fe fe-user me-2"></i>Change Password
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="dropdown-divider"></div>
                                    <ul className="list-unstyled">
                                        <li>
                                            <button className="dropdown-item" onClick={handleOnClick}>
                                                <i className="fe fe-power me-2"></i>Sign Out
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <ToastContainer />
        </>
    );
};

export default DashHeader;
