import React from 'react'
import c1 from '../../assets/images/gray-logo-airbnb.svg'
import c2 from '../../assets/images/gray-logo-discord.svg'
import c3 from '../../assets/images/gray-logo-intercom.svg'
import c4 from '../../assets/images/gray-logo-netflix.svg'
import c5 from '../../assets/images/gray-logo-stripe.svg'

const Clients = () => {
    return (
        <>
            {/* <div className="container py-8">
                <div className="row">
                    <div className="offset-xl-1 col-xl-10 col-md-12 col-12">

                        <div className="text-center mb-6 px-md-8">
                            <span className="ls-md">TRUSTED BY MILLIONS OF DEVELOPERS
                                &amp; THOUSANDS OF ENTERPRISE TEAMS</span>
                        </div>

                        <div className="row text-center">

                            <div className="col">
                                <div className="mb-4 mb-lg-0">
                                    <img src={c1} alt="" className="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="mb-4 mb-lg-0">
                                    <img src={c2} alt="" className="" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="mb-4 mb-lg-0">
                                    <img src={c3} alt="" className="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="mb-4 mb-lg-0">
                                    <img src={c4} alt="" className="" />
                                </div>
                            </div>

                            <div className="col">
                                <div className="mb-4 mb-lg-0">
                                    <img src={c5} alt="" className="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Clients
