import React from 'react'
import react from '../../assets/Tech/React-Logo.webp'
import nodejs from '../../assets/Tech/Note-.js-Logo.webp'
import js from '../../assets/Tech/Java-Script-Logo.webp'
import Android from '../../assets/Tech/Android.webp'
import express from '../../assets/images/expressjs.png'
import mongodb from '../../assets/images/mongodb.png'
import redux from '../../assets/images/redux.png'
import reactnav from '../../assets/images/reactnavigation.png'
import firebase from '../../assets/images/firebase.png'
import axios from '../../assets/images/axios.png'
import expo from '../../assets/images/expo.png'
import apple from '../../assets/Tech/Apple.webp';
import feacture from '../../assets/images/feature-img2.png'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Stats from '../Stats/Stats'
import Services from '../Services/Services'
import Reaview from '../Review/Review'
import Clients from '../Clients/Clients'
import WorkFlow from '../WorkFlow/WorkFlow'





const AppDevelopment = () => {
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0">
                <img src={feacture} alt="..."
                  className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">

              <div className="ps-lg-7">
                <span className=" text-primary ls-md text-uppercase fw-semibold">Our Services</span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">Application Development</h2>
                <h3>Our app development service provides comprehensive, tailored solutions to meet our clients' unique needs. Utilizing the latest technologies and design principles, we create intuitive mobile applications for a diverse range of industries. </h3>
                <div className="mt-5 row">

                  <div className="col">
                    <ul className="list-unstyled fs-4 fw-medium">
                      <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Customize Solution</li>
                      <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Cutting-edge Technologies</li>
                      <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>User Friendly</li>
                      <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Protected & Secure</li>
                      <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Comprehensive Support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="py-10">
        <div class="container">
          <h2 className="h1 fw-bold mt-3">Our Modern Full-Stack Tech Built
            For Performance.
          </h2>
          <div class="row">
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={react} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1"> React Native</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={js} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1">JavaScript</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={redux} width={50} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1">Redux</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-lg ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={apple} />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">Ios</h2>

                </div>
              </div>

            </div>
            <div class="col-md-6 col-lg-2 border-top-lg border-bottom border-end-md ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={nodejs} alt="" />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">NodeJs</h2>
                </div>
              </div>

            </div>
            <div class="col-md-6 col-lg-2 border-top-lg border-bottom ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={reactnav} width={50} alt="" />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">React Navigation</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={firebase} width={60} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1"> FireBase</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={axios} width={140} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1">Axios</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={Android} alt="" />
                </div>
                <div class="lh-1">
                  <h2 class="mb-1">Android</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-lg ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={expo} width={50} />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">Expo</h2>

                </div>
              </div>

            </div>
            <div class="col-md-6 col-lg-2 border-top-lg border-bottom border-end-md ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={mongodb} width={50} alt="" />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">MongoDB</h2>
                </div>
              </div>

            </div>
            <div class="col-md-6 col-lg-2 border-top-lg border-bottom ">

              <div class="py-7 text-center">
                <div class="mb-3">
                  <img src={express} alt="" width={95} />
                </div>

                <div class="lh-1">
                  <h2 class="mb-1">Express Js</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Stats />
      <Services />
      <WorkFlow/>
      <Reaview />
      <Clients />
      <Footer />
    </>
  )
}

export default AppDevelopment
