import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/adminLogin.png'
import { Link, useParams } from 'react-router-dom'
import services from '../../../Services/LeadServices'
import moment from 'moment'

const LeadForm = () => {
    const { id } = useParams();
    const [LeadDetails, setLeadDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await services.getLead(id);
                console.log('data', data);
                
                setLeadDetail(data); // Ensure this line is executed to set the state
            } catch (error) {
                console.error('Error fetching contacts:', error.message);
                setError('Failed to fetch contact details.');
            } finally {
                setLoading(false);
            }
        };
        fetchData(); // Pass the correct ID to fetch the data
    }, [id]);


    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }

    if (!LeadDetails) {
        return <h2>Contact not found!</h2>;
    }

    return (
        <>
            <div className="row">
                <div className='col-12 col-md-6'>
                    <img src={logo} className='img-fluid' width={400} alt="" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row">
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="firstname">First Name</label>
                                    <input type="text" id="firstname" name="firstname" value={LeadDetails.firstname} className="form-control" placeholder="First Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="lastname">Last Name</label>
                                    <input type="text" id="lastname" value={LeadDetails.lastname} name="lastname" className="form-control" placeholder="Last Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" value={LeadDetails.email} className="form-control" placeholder="Email" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="phone">Phone Number</label>
                                    <input type="tel" id="phone" name="phone" value={LeadDetails.phone} className="form-control" placeholder="Phone Number" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="source">Source</label>
                                    <input type="text" id="source" name="source" value={LeadDetails.source} className="form-control" placeholder="Source" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="status">Status</label>
                                    <input type="text" id="status" name="status" value={LeadDetails.status } className="form-control" placeholder="Status" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companyname">Company Name</label>
                                    <input type="text" id="companyname" name="companyname" value={LeadDetails.companyname} className="form-control" placeholder="Company Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companyCity">City</label>
                                    <input type="text" id="companyCity" name="companyCity" value={LeadDetails.city} className="form-control" placeholder="City" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="field">Field</label>
                                    <input type="text" id="field" name="field" value={LeadDetails.field} className="form-control" placeholder="Field" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="Agent">Agent</label>
                                    <input type="text" id="Agent" name="Agent" value={LeadDetails.agent} className="form-control" placeholder="Agent" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="Subject">Subject</label>
                                    <input type="text" id="Subject" name="Subject" value={LeadDetails.subject} className="form-control" placeholder="Subject" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="Date">Date</label>
                                    <input type="text" id="Date" name="Date" value={moment(LeadDetails.leaddate).utc().format('LL')} className="form-control" placeholder="Date" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="message">Message</label>
                                    <textarea id="message" name="message" className="form-control" rows="5" placeholder="Message" value={LeadDetails.message} readOnly></textarea>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="created">CreatedAt</label>
                                    <input type="text" className='form-control' value={moment(LeadDetails.leaddate).utc().format('llll')} />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="created">Last UpdatedAt</label>
                                    <input type="text" className='form-control' value={moment(LeadDetails.updatedAt).utc().format('llll')} />
                                </div>
                                <div className="col-12">
                                    <Link to={'/admin/dashboard/leads'} className="btn btn-primary">Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeadForm
