import axios from 'axios'

const apiUrl = 'http://localhost:3001/api/contact-form/'

const contactFrom = async (firstname, lastname, email, phone, message) => {
    try {
        const formData = {
            firstname,
            lastname,
            email,
            phone,
            message
        };

        // Log form data
        console.log('Form Data:', formData);

        const response = await axios.post(apiUrl + 'contact', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.data.success) {
            //console.log('Form submitted successfully:', response.data);
            return response.data;
        } else {
            throw new Error(response.data.message || 'An error occurred while submitting the contact form');
        }
    } catch (error) {
        console.error('error', error)
        console.error('Error submitting contact:', error.response ? error.response.data : error.message);
        throw error;
    }
};


const getContacts = async () => {
    try {
        const response = await axios.get(apiUrl + 'contact');
        // console.log('response from services', response);
        return response.data
    } catch (error) {
        // Log detailed error information
        console.error('Error getting contacts:', error.response ? error.response.data : error.message);
        // Rethrow the error to handle it upstream if needed
        throw error;
    }
};

const contactDetail = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}companycontact/${id}`);
        // console.log(response);

        //console.log('check response', response);
        return response.data; // Assuming response.data contains the contact detail

    } catch (error) {
        console.error('Error getting contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}

const contactDetete = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}companycontact/${id}`)
        // response check
        console.log(response);
        return response.data

    } catch(error) {
        console.error('Error getting deleting contacts:', error.response ? error.response.data : error.message);
        throw error; // Rethrow the error for handling in the component
    }
}
export default {
    contactFrom, getContacts, contactDetail, contactDetete
};


