import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/adminLogin.png';
import reviewFrom from '../../../Services/customerServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomerReview = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); // For success message
    const [photo, setFile] = useState(null);





    const [review, setReview] = useState({
        name: '',
        companyName: '',
        designation: '',
        rating: '',
        message: '',
        status: '',
        featured: '',
        photo: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const data = await reviewFrom.getReview(id);
                    setReview(data[0]);
                } catch (err) {
                    setError('Failed to fetch review data');
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        } else {
            setLoading(false);
        }
    }, [id]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReview({
            ...review,
            [name]: value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess('');

        const formData = new FormData();
        for (const key in review) {
            formData.append(key, review[key]);
        }
        if (photo) {
            formData.append('photo', photo); // Append the photo if exists
        }

        try {
            if (id) {
                // Update mode
                await reviewFrom.updateReview(id, formData);
                console.log('response message form jsx', await reviewFrom.updateReview(id, formData));

                toast.success('Review updated successfully!');
            } else {
                // Add mode
                await reviewFrom.review(review.name, review.companyName, review.message, review.designation, review.rating, photo, review.featured, review.status);
                toast.success('Review added successfully!');
            }
            navigate('/admin/dashboard/customer-review');
        } catch (err) {
            toast.error('Failed to save review data');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img
                        src={(__dirname, `../public/Storage/CustomerReviewImages/${review.photo}`)}
                        className="img-fluid"
                        width={400}
                        alt="Customer Review" crossOrigin='anonymous'
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row" onSubmit={handleSubmit}>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={review.name}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companyName">Company Name</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        name="companyName"
                                        value={review.companyName}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Company Name"
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="designation">Designation</label>
                                    <input
                                        type="text"
                                        id="designation"
                                        name="designation"
                                        value={review.designation}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Designation"
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="rating">Rating</label>
                                    <select
                                        id="rating"
                                        name="rating"
                                        value={review.rating}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="">Select Rating</option>
                                        <option value="1">1 Star</option>
                                        <option value="2">2 Star</option>
                                        <option value="3">3 Star</option>
                                        <option value="4">4 Star</option>
                                        <option value="5">5 Star</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        className="form-control"
                                        rows="5"
                                        placeholder="Message"
                                        value={review.message}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="status">Status</label>
                                    <select
                                        id="status"
                                        name="status"
                                        value={review.status}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="*">Select</option>
                                        <option value="Published">Published</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="featured">Featured</label>
                                    <select
                                        id="featured"
                                        name="featured"
                                        value={review.featured}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="*">Select</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Not">Not Partner</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="photo">Upload Photo</label>
                                    {id ? <img src={review.photo} alt="Reviewer Uploaded" /> : null}

                                    <input
                                        className='form-control'
                                        type="file"
                                        name="photo"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        accept="image/*"
                                    />
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {id ? 'Update Review' : 'Add Review'}
                                    </button>
                                    <Link to="/admin/dashboard/customer-review" className="btn btn-secondary ms-2">Back</Link>
                                </div>
                            </form>
                            {success && <div className="alert alert-success mt-3">{success}</div>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default CustomerReview;
