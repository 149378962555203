import React from 'react'
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';


const Sidebar = () => {
    return (
        <>

            {/* Collapse Button */}
            <button className="btn btn-primary d-md-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars"></i> Menu
            </button>

            {/* Sidebar */}

            <div className="col-md-3 col-lg-2 bg-white p-0 collapse d-md-block sidebar" id="sidebar">
                <div className="rounded nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <ListGroup variant="flush">
                        <ListGroup.Item action as={Link} to="/admin/dashboard/"><i class="bi bi-speedometer text-primary me-4" ></i> Dashboard</ListGroup.Item>
                        <ListGroup.Item action as={Link} to="/admin/dashboard/contact-form"><i class="bi bi-person-lines-fill text-primary me-4"></i> Contact From</ListGroup.Item>
                        <ListGroup.Item action as={Link} to="/admin/dashboard/customer-review"><i class="bi bi-star-half text-primary me-4"></i> Customer Review</ListGroup.Item>
                        {/* <ListGroup.Item action as={Link} to="/admin/dashboard/clients"><i class="bi bi-person-check text-primary me-4"></i> Clients</ListGroup.Item> */}
                        {/* <ListGroup.Item action as={Link} to="/admin/dashboard/job-post"><i class="bi bi-backpack-fill text-primary me-4"></i> Jobs</ListGroup.Item> */}
                        <ListGroup.Item action as={Link} to="/admin/dashboard/leads"><i class="bi bi-clipboard-plus-fill me-4 text-primary"></i> Leads</ListGroup.Item>
                        {/* <ListGroup.Item action as={Link} to="/admin/dashboard/admin"><i class="bi bi-person-fill-up text-primary me-4"></i> Admin</ListGroup.Item> */}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default Sidebar
