import React from 'react';
import avtar from '../../assets/images/avatar-1.jpg';
import avtar2 from '../../assets/images/avatar-2.jpg';
import avtar3 from '../../assets/images/avatar-3.jpg';
import avtar4 from '../../assets/images/avatar-4.jpg';
import avtar5 from '../../assets/images/avatar-5.jpg';
import avtar6 from '../../assets/images/avatar-6.jpg';
import avtar7 from '../../assets/images/avatar-7.jpg';
import feature from '../../assets/images/girl2.png'
import html5 from '../../assets/images/html5.png'
import css3 from '../../assets/images/css3.png'
import Javascript from '../../assets/images/js.png'
import jquery from '../../assets/images/jquary.png'
import bootstrap from '../../assets/images/bootstrap.png'
import reactjs from '../../assets/images/react.png'
import reactnavite from '../../assets/images/reactnative.png'
import nodejs from '../../assets/images/nodejs.png'
import expressjs from '../../assets/images/expressjs.png'
import mongoDb from '../../assets/images/mongodb.png'
import nextjs from '../../assets/images/nextjs.png'
import redux from '../../assets/images/redux.png'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css'
import { Link } from 'react-router-dom';


const Carrer = () => {
    return (
        <>
            <Header />
            <div className="py-8 bg-white">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xl-6 col-lg-6 col-12">
                            <div className="mb-5">
                                <h1 className="display-3 mb-4 fw-bold">Join the team, we’re growing fast!</h1>
                                <p className="lead mb-4 pe-xl-12">We’re looking for incredible people to build on our strong momentum. Help us power the brands you know and love.</p>
                                <a href="#position" className="btn btn-primary">See All Open Positions</a>
                                <p className="mt-4 mb-0">69 open positions across <a href="#">all offices</a> and <a href="#">all teams</a>.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="row">

                                <div className="col-md-4 col-4 px-1">
                                    <div className="bg-cover rounded-3 mb-2 h-12rem" style={{ backgroundImage: `url(${avtar})` }}></div>
                                    <div className="bg-cover rounded-3 mb-2 h-18rem" style={{ backgroundImage: `url(${avtar6})` }}></div>
                                </div>

                                <div className="col-md-4 col-4 px-1">
                                    <div className="bg-cover rounded-3 mb-2 h-18rem" style={{ backgroundImage: `url(${avtar3})` }}></div>
                                    <div className="bg-cover rounded-3 mb-2 h-18rem" style={{ backgroundImage: `url(${avtar4})` }}></div>
                                </div>

                                <div className="col-md-4 col-4 px-1">
                                    <div className="bg-cover rounded-3 mb-2 h-13rem" style={{ backgroundImage: `url(${avtar5})` }}></div>
                                    <div className="bg-cover rounded-3 mb-2 h-13rem" style={{ backgroundImage: `url(${avtar2})` }}></div>
                                    <div className="bg-cover rounded-3 mb-2 h-13rem" style={{ backgroundImage: `url(${avtar7})` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="row text-center">
                    <div className="col-md-12 px-lg-10 mb-8 mt-6">
                        <span className="text-uppercase text-primary fw-semibold ls-md"> Career
                        </span>
                        <h2 className="h1 fw-bold mt-3">Career With Us
                        </h2>
                        <p className="mb-0 fs-4">Join our team and embark on a journey where your skills meet endless opportunities. We are a dynamic digital agency that believes in the transformative power of technology. Here, we don't just see technology as a tool; we see it as a catalyst for creating remarkable solutions and shaping your career success.</p>
                    </div>
                </div>
                <div className=" py-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-laptop lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Fostering a Culture of Learning & Growth</h3>
                                    <p>At our organization, we prioritize learning and growth by providing access to comprehensive training programs and valuable mentorship opportunities. We believe in cultivating a culture of lifelong learning that empowers our employees to continuously enhance their skills and excel in their careers.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-chat-heart lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Commitment to Honest Communication</h3>
                                    <p>We are passionate about honest communication and believe it is vital for creating exceptional web and mobile applications. Our team focuses on transparency and collaboration, ensuring that every voice is heard as we redefine user experiences in the digital landscape.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-person-arms-up lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Embracing Responsibility</h3>
                                    <p>Responsibility is at the core of our values. We take pride in our commitment to crafting extraordinary web and mobile applications, understanding the impact our work has on users and the broader community.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-person-hearts lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Promoting Respect and Inclusivity</h3>
                                    <p>We champion respect and inclusivity in our workplace. Our commitment to diversity enhances creativity and innovation, allowing us to craft web and mobile applications that resonate with users from all walks of life.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-people lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Supporting Work-Life Balance
                                    </h3>
                                    <p>Recognizing the importance of work-life balance, we offer flexible working arrangements to accommodate the diverse needs of our employees. We understand that personal time is essential for well-being and productivity.


                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-person-lines-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h3 className="fw-bold">Encouraging Teamwork & Collaboration
                                    </h3>
                                    <p>We believe in the power of teamwork and collaboration. By encouraging employees to work together, share knowledge, and support one another, we create an environment where everyone can contribute to achieving common goals and overcoming challenges.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
                <div className="py-8">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="mb-4 mb-lg-0">
                                    <img src={feature} alt="..."
                                        className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">

                                <div className="ps-lg-7">
                                    <span className=" text-primary ls-md text-uppercase fw-semibold">Our Benefit's</span>
                                    <h2 className="display-4 mt-4 mb-3 fw-bold">Explore Exciting Career Opportunities</h2>
                                    <h3>Discover a world of possibilities with us. We're a dynamic team dedicated to leveraging technology to drive innovation and success. Join us and be a part of shaping the future through your skills and passion. Your career journey begins here. </h3>
                                    <div className="mt-5 row">

                                        <div className="col">
                                            <ul className="list-unstyled fs-4 fw-medium">
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Cultivating Continuous Growth </li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Transparent Connections</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Accountability in Action</li>
                                            </ul>
                                        </div>
                                        <div className="col">

                                            <ul className="list-unstyled fs-4 fw-medium">
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Diversity Matters </li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Balancing Life and Work</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Collaborative Success</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container py-8">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="mb-8 text-center">
                            <h4>We invite you to become part of our innovative technology team</h4>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <OwlCarousel
                        className='owl-theme'
                        loop
                        nav
                        autoplay
                        autoplayTimeout={3000} // Adjust the timeout as needed (in milliseconds)
                        responsive={{
                            0: {
                                items: 1, // For mobile view, show 1 item
                            },
                            600: {
                                items: 2, // For medium devices, show 2 items
                            },
                            1000: {
                                items: 5, // For large devices, show 5 items
                            },
                        }}
                        margin={10} // Add some margin between items
                    >
                        <div className='item'>
                            <img src={html5} alt="HTML5" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={css3} alt="CSS3" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={Javascript} alt="JavaScript" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={bootstrap} alt="Bootstrap" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={jquery} alt="jQuery" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={reactjs} alt="ReactJS" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={reactnavite} alt="React Native" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={redux} alt="Redux" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={nextjs} alt="Next.js" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={nodejs} alt="Node.js" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={mongoDb} alt="MongoDB" className="carousel-image" />
                        </div>
                        <div className='item'>
                            <img src={expressjs} alt="Express.js" className="carousel-image" />
                        </div>
                    </OwlCarousel>
                </div>
            </div>
           
            <div className="container py-8">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-5">
                            <h2 className="fw-bold">Open positions join with Us</h2>
                            <p className="mb-0">Join our team and embark on a journey where your skills meet endless opportunities</p>
                        </div>
                        <div>
                            {/* <h3 className='text-center text-primary'>Sorry Currunly We have No Openings</h3> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-lg fs-4">
                                <tbody>
                                    <tr>
                                        <td><Link to='/job' className="text-inherit">Marketing Designer</Link></td>
                                        <td>San Francisco <span className="text-muted"> (Remote)</span></td>
                                        <td className="text-end"><Link to='/job' className="text-muted"><i className="bi bi-arrow-right "></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td><Link to='/job' className="text-inherit">Product Design Lead</Link></td>
                                        <td>United Kingdom</td>
                                        <td className="text-end"><Link to='/job' className="text-muted"><i className="bi bi-arrow-right "></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td><Link to='/job' className="text-inherit">Brand Designer</Link></td>
                                        <td>Canada <span className="text-muted"> (Remote)</span> </td>
                                        <td className="text-end"><Link to='/job' className="text-muted"><i className="bi bi-arrow-right "></i></Link></td>
                                    </tr>
                                    <tr>
                                        <td><Link to='/job' className="text-inherit">Sr. UX Engineer</Link></td>
                                        <td>Ireland <span className="text-muted"> (Remote)</span> </td>
                                        <td className="text-end"><Link to='/job' className="text-muted"><i className="bi bi-arrow-right "></i></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div className=" py-8">
                <div className="container">
                    <p className="text-primary ls-md text-uppercase fw-semibold">Hiring Process
                    </p>
                    <div className="row">
                        <div className="col-md-6 col-lg-2 col-12">
                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-send-check-fill  lh-1" style={{ fontSize: '24px' }}></i></div>
                                <h4 className="fw-bold">Application Submittion</h4>
                                <p>Submit your resume and cover letter through our online portal.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-12">

                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-list-stars lh-1" style={{ fontSize: '24px' }}></i></div>
                                <h4 className="fw-bold">Application Shortlist</h4>
                                <p>Shortlisted candidates are selected based on qualifications and experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-12">

                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-bar-chart-fill lh-1"></i></div>
                                <h4 className="fw-bold">Technical Interivew</h4>
                                <p>Demonstrate your technical skills and problem-solving abilities with our expert panel.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-12">

                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-bookmark-heart-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                <h4 className="fw-bold">HR Interview</h4>
                                <p> Discuss your fit with our company culture, values, and career aspirations.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-12">

                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-hand-thumbs-up-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                <h4 className="fw-bold">Decision & Offer</h4>
                                <p>Successful candidates receive an offer detailing role, salary, and benefits.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-12">

                            <div className="mb-6 mb-lg-0 fs-4">

                                <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-people-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                <h4 className="fw-bold">Join</h4>
                                <p>Complete the onboarding process and start your journey with us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default Carrer;
