import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import girl from '../../../assets/images/girl3.png'


const JobDescription = () => {
    return (
        <>
            <Header />
            <div>
                <div className="py-8">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-lg-6 col-md-12 col-12">

                                <div className="pe-lg-6 ps-lg-6">
                                    <span className="text-primary ls-md text-uppercase fw-semibold">Why MindScape Technologies
                                    </span>
                                    <h2 className="display-4 mt-4 mb-3 fw-bold">Unlock Your Potential with Us</h2>
                                    <h5>Discover a world of possibilities with us. We're a dynamic team dedicated to leveraging technology to drive innovation and success. Join us and be a part of shaping the future through your skills and passion. Your career journey begins here.</h5>
                                    <div className="mt-6">

                                        <div className="d-flex mb-4">
                                            <div>
                                                <i className="bi bi-cup-hot-fill lh-1 text-primary" style={{ fontSize: '24px' }}></i>
                                            </div>
                                            <div className="ms-3">

                                                <h3 className="mb-2">Career opportunities on your terms </h3>
                                                <p className="mb-0 fs-4">
                                                    Discover career opportunities tailored to your unique aspirations and needs. At MindScape Technologies, we understand that each individual's career journey is distinct.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-4">
                                            <div>
                                                <i className="bi bi-person-workspace lh-1 text-primary" style={{ fontSize: '24px' }}></i>
                                            </div>

                                            <div className="ms-3">
                                                <h3 className="mb-2">Enriching job experiences</h3>
                                                <p className="mb-0 fs-4">
                                                    Unlock enriching job experiences that foster growth and fulfillment. At MindScape Technologies, we believe that work should be more than just a job—it should be a pathway to personal and professional development
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex ">
                                            <div>
                                                <i className="bi bi-person-check-fill lh-1 text-primary" style={{ fontSize: '24px' }}></i>
                                            </div>

                                            <div className="ms-3">
                                                <h3 className="mb-2 lh-1">An employment that you can trust</h3>
                                                <p className="mb-0 fs-4">
                                                    Experience employment you can trust with MindScape Technologies. In today's dynamic job market, finding a trustworthy employer is paramount.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="mt-4 mt-lg-0">
                                    <img src={girl} alt="..."
                                        className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" py-8">
                    <div className="container">
                        <p className="text-primary ls-md text-uppercase fw-semibold">Hiring Process
                        </p>
                        <div className="row">
                            <div className="col-md-6 col-lg-2 col-12">
                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-send-check-fill  lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h4 className="fw-bold">Application Submittion</h4>
                                    <p>Submit your resume and cover letter through our online portal.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-list-stars lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h4 className="fw-bold">Application Shortlist</h4>
                                    <p>Shortlisted candidates are selected based on qualifications and experience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-bar-chart-fill lh-1"></i></div>
                                    <h4 className="fw-bold">Technical Interivew</h4>
                                    <p>Demonstrate your technical skills and problem-solving abilities with our expert panel.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-bookmark-heart-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h4 className="fw-bold">HR Interview</h4>
                                    <p> Discuss your fit with our company culture, values, and career aspirations.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-hand-thumbs-up-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h4 className="fw-bold">Decision & Offer</h4>
                                    <p>Successful candidates receive an offer detailing role, salary, and benefits.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">

                                <div className="mb-6 mb-lg-0 fs-4">

                                    <div className="icon-shape icon-lg bg-primary text-white rounded-circle text-center mb-4"><i className="bi bi-people-fill lh-1" style={{ fontSize: '24px' }}></i></div>
                                    <h4 className="fw-bold">Join</h4>
                                    <p>Complete the onboarding process and start your journey with us.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" py-8">
                    <div className="container">
                        <span className="text-primary ls-md text-uppercase fw-semibold">Open positions join with Us
                        </span>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-5">
                                    <h2 className="fw-bold">Mern Stack Developer</h2>
                                    <p className="mb-0">We are seeking a skilled and experienced MERN (MongoDB, Express.js, React, Node.js) Stack Developer to join our dynamic team. As a MERN Stack Developer, you will be responsible for designing, implementing, and maintaining full-stack applications. You will collaborate with cross-functional teams to deliver high-quality software solutions.</p>
                                </div>
                                <div className="mb-5">
                                    <h3>Responsibilities</h3>
                                    <div className="mt-5 row">

                                        <div className="col-lg-12 col-md-12 col-12">

                                            <ul className="list-unstyled fs-4 fw-medium">
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Develop and maintain robust and scalable web applications using the MERN stack. </li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Collaborate with front-end and back-end developers to define and implement innovative solutions.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Design and implement RESTful APIs for seamless integration between front-end and back-end systems.</li>

                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Work closely with UI/UX designers to create responsive and visually appealing user interfaces. </li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Troubleshoot, debug, and optimize code to ensure high performance and reliability.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Implement security and data protection measures in line with industry best practices.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Stay updated on emerging trends and technologies in web development.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <h3>Requirements</h3>
                                    <div className="mt-5 row">

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <ul className="list-unstyled fs-4 fw-medium">
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Proven experience as a MERN Stack Developer or similar role.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>In-depth knowledge of MongoDB, Express.js, React, and Node.js.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Strong proficiency in JavaScript, HTML, and CSS.</li>

                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Experience with version control systems, such as Git. </li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Familiarity with front-end frameworks like Bootstrap, Material-UI, etc.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Solid understanding of RESTful API design and integration.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Knowledge of database design and management, including both SQL and NoSQL databases.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Excellent problem-solving and communication skills.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Ability to work collaboratively in a team-oriented environment.</li>
                                                <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Bachelor’s degree in Computer Science or a related field is preferred.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>If you are a passionate and skilled MERN Stack Developer looking for an exciting opportunity to contribute to cutting-edge projects, we would love to hear from you. Apply now and be a part of our innovative development team!</p>
                        </div>

                    </div>
                </div>
                <div className=" py-8">
                    <div className="container">
                        <div className="card mb-4">

                            <div className="card-header">
                                <h3 className="mb-0">Application For Mern Stack Developer</h3>
                            </div>

                            <div className="card-body">

                                <form className="row">
                                    <div className="mb-3 col-12 col-md-6">
                                        <label className="form-label" for="fname">First Name</label>
                                        <input type="text" id="fname" className="form-control" placeholder="First Name" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6">
                                        <label className="form-label" for="lname">Last Name</label>
                                        <input type="text" id="lname" className="form-control" placeholder="Last Name" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6">
                                        <label className="form-label" for="phone">Phone Number </label>
                                        <input type="tel" id="phone" className="form-control" placeholder="Phone" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6">
                                        <label className="form-label" for="phone">Email Address </label>
                                        <input type="email" id="email" className="form-control" placeholder="email" required />
                                    </div>

                                    <div className="mb-3 col-12 col-md-12">
                                        <label className="form-label" for="address1">Address Line 1 (Current Address)</label>
                                        <input type="text" id="address1" className="form-control" placeholder="Address" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-12">
                                        <label className="form-label" for="address2">Address Line 2
                                            (Permanent Address)</label>
                                        <input type="text" id="address2" className="form-control" placeholder="Address" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-4">
                                        <label className="form-label">Experience</label>
                                        <select className="selectpicker form-control" data-width="100%">
                                            <option value="">Select Experience</option>
                                            <option value="1">Fresher</option>
                                            <option value="2">0-1</option>
                                            <option value="3">1-3</option>
                                            <option value="3">5-15</option>
                                            <option value="3">15-30</option>
                                            <option value="3">30+</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-12 col-md-4">
                                        <label className="form-label">Country</label>
                                        <select className="selectpicker form-control" data-width="100%">
                                            <option value="">Select Country</option>
                                            <option value="India">India</option>
                                            <option value="UK">UK</option>
                                            <option value="USA">USA</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-12 col-md-4">
                                        <label className="form-label" for="zipCode">Zip/Postal Code</label>
                                        <input type="text" id="zipCode" className="form-control" placeholder="Zip" required />
                                    </div>
                                    <div className="mb-3 col-12 col-md-6">
                                        <p className="mb-1 text-dark">Resume</p>
                                        <div className="input-group mb-1">
                                            <input type="file" className="form-control" id="inputLogo" />
                                            <label className="input-group-text" for="inputLogo">Upload</label>
                                        </div>
                                        <small className="text-muted">(Upload your Resume - 5 Mb )</small>
                                    </div>
                                    <div className="mb-3 col-12 col-md-6">
                                        <p className="mb-1 text-dark">Cover Letter (Optional)</p>
                                        <div className="input-group mb-1">
                                            <input type="file" className="form-control" id="inputLogo" />
                                            <label className="input-group-text" for="inputLogo">Upload</label>
                                        </div>
                                        <small className="text-muted">(Upload your Cover Letter - 5 Mb )</small>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="shippingAddress" />
                                            <label className="form-check-label" for="shippingAddress">
                                                <small className="mb-0">By click start learning, you agree to our <a href="#">Terms of
                                                    Service and Privacy Policy.</a></small>
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="mt-3 col-4 col-md-4 btn btn-primary">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default JobDescription
