import React, { useState } from 'react';
import logo from '../../../assets/images/adminLogin.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ChangePassword = () => {
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPass !== confirmPass) {
            toast.error('New password and confirmation do not match');
            return;
        }

        setLoading(true); // Start loading

        try {
            const response = await axios.post(`http://localhost:3001/api/admin/change-password`, {
                currentPassword: currentPass,
                newPassword: newPass,
                conformPassword: confirmPass
            });

            if (response.data.success) {
                toast.success('Password updated successfully!');
                setCurrentPass('');
                setNewPass('');
                setConfirmPass('');
            } else {
                toast.error(response.data.message || 'Failed to update password');
            }
        } catch (error) {
            console.error('Password update error:', error.response ? error.response.data : error.message);
            toast.error('An error occurred while updating the password');
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <>
            <div className="row">
                <div className='col-12 col-md-6'>
                    <img src={logo} className='img-fluid' width={400} alt="Admin Login" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row" onSubmit={handleSubmit}>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="currentPass">Current Password</label>
                                    <input
                                        type="password"
                                        id="currentPass"
                                        name="currentPass"
                                        className="form-control"
                                        placeholder="Current Password"
                                        value={currentPass}
                                        onChange={(e) => setCurrentPass(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="password">New Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        value={newPass}
                                        onChange={(e) => setNewPass(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="confirmPass">Confirm Password</label>
                                    <input
                                        type="password"
                                        id="confirmPass"
                                        name="confirmPass"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        value={confirmPass}
                                        onChange={(e) => setConfirmPass(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <Link to={'/admin/dashboard/'} className="btn btn-primary me-3">Back</Link>
                                    <button type="submit" className="btn btn-success me-3" disabled={loading}>
                                        {loading ? 'Updating...' : 'Update Password'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default ChangePassword;
