import React, { useState } from 'react';
import logo from '../../assets/images/MindScape.png';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { Button } from 'react-bootstrap';
import reviewImage from '../../assets/images/check-mark-with-review-stars.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import reviewService from '../../Services/customerServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const CustomerReview = () => {
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [designation, setDesignation] = useState('');
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState('');
    const navigator = useNavigate()
    const [photo, setFile] = useState(null);

    const handleRating = (rate) => {
        setRating(rate);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await reviewService.review(name, companyName, message, designation, rating, photo);
            toast('Thank You So Much Review Has Been Successfully Received')
            setTimeout(() => {
                navigator('/mindscapetechnologies/thankyou')
            }, 3000);

        } catch (error) {
            console.error('Error submitting review:', error);
            alert('Failed to submit review. Please try again.');
        }
    };

    return (
        <>
            <Header />
            <div className="mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mt-5 mb-5">
                            <img src={reviewImage} className="img-fluid" width={400} alt="Review" />
                            <img src={logo} className="img-fluid" width={400} alt="Logo" />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="mb-0">Customer Feedback Form</h3>
                                </div>
                                <div className="card-body">
                                    <form className="row" onSubmit={handleSubmit}>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="name">Name</label> <span className='text-danger'>*</span>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="companyName">Company Name</label> <span className='text-danger'>*</span>
                                            <input
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                className="form-control"
                                                placeholder="Company Name"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="designation">Designation</label> <span className='text-danger'>*</span>
                                            <input
                                                type="text"
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Designation"
                                                value={designation}
                                                onChange={(e) => setDesignation(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label">Rating</label> <span className='text-danger'>*</span>
                                            <div className="d-flex align-items-center">
                                                {[1, 2, 3, 4, 5].map((star) => (
                                                    <span key={star}>
                                                        <FontAwesomeIcon
                                                            icon={faStar}
                                                            onClick={() => handleRating(star)}
                                                            className={`star ${rating >= star ? 'text-warning' : 'text-muted'}`}
                                                            style={{ cursor: 'pointer', fontSize: '24px', marginRight: '2px' }}
                                                        />
                                                        {rating >= star + 0.5 && rating < star + 1 && (
                                                            <FontAwesomeIcon
                                                                icon={faStarHalfAlt}
                                                                onClick={() => handleRating(star + 0.5)}
                                                                className={`star text-warning`}
                                                                style={{ cursor: 'pointer', fontSize: '24px', marginLeft: '-12px' }}
                                                            />
                                                        )}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="mb-3 col-12 col-md-12">
                                            <label className="form-label" htmlFor="photo">Photo</label>
                                            <input className='form-control' type="file" name="photo" onChange={(e) => setFile(e.target.files[0])} accept="image/*" />

                                        </div>
                                        <div className="mb-3 col-12 col-md-12">
                                            <label className="form-label" htmlFor="message">Message</label> <span className='text-danger'>*</span>
                                            <textarea
                                                id="message"
                                                name="message"
                                                className="form-control"
                                                rows="5"
                                                placeholder="Message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <Button type="submit" className="btn btn-primary">Submit Review</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />

            </div>
            <Footer />
        </>
    );
};

export default CustomerReview;
