import React from 'react'

const Job = () => {
    return (
        <>
            <div className="card card-hover">
                <div className="container py-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div>
                                    <h2 className="fw-bold">Job</h2>
                                    <p className="mb-0">All Job Post Are here</p>
                                </div>
                                <button type="button" className="btn btn-primary">Add New Opning</button>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Job Type</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>
                                                <a href="#!"><i className="bi bi-eye-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-pen-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-trash3-fill text-primary"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>
                                                <a href="#!"><i className="bi bi-eye-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-pen-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-trash3-fill text-primary"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>
                                                <a href="#!"><i className="bi bi-eye-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-pen-fill text-primary me-2"></i></a>
                                                <a href="#!"><i className="bi bi-trash3-fill text-primary"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul className="pagination pagination-sm justify-content-center mt-4">
                                    <li className="page-item active" aria-current="page">
                                        <span className="page-link">
                                            1
                                            <span className="visually-hidden">(current)</span>
                                        </span>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Job
