import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import reviewFrom from '../../../Services/customerServices'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const CustomerReview = () => {


    const [customerReview, setCustomerReview] = useState([])


    useEffect(() => {
        const fatchdata = async () => {
            const data = await reviewFrom.allReview()
            setCustomerReview(data)
        }
        fatchdata()
    }, [])

    const handleDelete = async (id) => {
        const conform = window.confirm('Are you Sure want to Delete Review')
        try {
            if (conform) {
                await reviewFrom.reviewDelete(id)
                toast.success('review deleted')
                setCustomerReview(prevreview => prevreview.filter(review => review._id !== id));
            }
        } catch (error) {
            toast.error('Failed to delete review');
            console.error(error);
        }
    }




    const [currentPage, setCurrentPage] = useState(1);
    const currentPerPage = 5;

    // Calculate index ranges
    const indexOfTheLastReview = currentPage * currentPerPage;
    const indexOfTheFirstReview = indexOfTheLastReview - currentPerPage;

    // Slice the array for current page reviews
    const currentReviews = customerReview.slice(indexOfTheFirstReview, indexOfTheLastReview);

    // Calculate total pages
    const totalPages = Math.ceil(customerReview.length / currentPerPage);
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return 'green';
            case 'Pending':
                return 'orange';
            case 'Rejected':
                return 'red';
            default:
                return 'black';
        }
    }

    const getFeaturedColor = (featured) => {
        switch (featured) {
            case "Partner":
                return 'green';
            case 'Not':
                return 'orange';
        }
    }

    return (
        <>
            <div className="card card-hover">
                <div className="container py-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <div>
                                    <h2 className="fw-bold">Customer Review</h2>
                                    <p className="mb-0">All Contact Reviews here</p>
                                </div>
                                <Link to={'/admin/dashboard/update-review'} className="btn btn-primary">Add Review</Link>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">First</th>
                                            <th scope="col">Company Name</th>
                                            <th scope='col'>Status</th>
                                            <th scope='col'>Featured</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentReviews.map((review, index) => (
                                            <tr key={review.id}>
                                                <th>{(currentPage - 1) * currentPerPage + index + 1}</th>
                                                <th>{review.name}</th>
                                                <th>{review.companyName}</th>
                                                <th key={review.id} style={{ color: getStatusColor(review.status) }}>{review.status}</th>
                                                <th key={review.id} style={{ color: getFeaturedColor(review.featured) }}>{review.featured}</th>
                                                <td>
                                                    <Link to={`/admin/dashboard/view-review/${review._id}`}><i className="bi bi-eye-fill text-primary me-2"></i></Link>
                                                    <Link to={`/admin/dashboard/customer-review/${review._id}`}><i className="bi bi-pen-fill text-success me-2"></i></Link>
                                                    <Link onClick={() => { handleDelete(review._id) }}><i className="bi bi-trash3-fill text-danger me-2"></i></Link>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul className="pagination pagination-sm">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li
                                            key={index + 1}
                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                            aria-current={currentPage === index + 1 ? 'page' : undefined}
                                        >
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default CustomerReview;
