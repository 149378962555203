import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import contactTable from '../../../Services/ContactServices'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'


const ContactForm = () => {
    const { id } = useParams();


    const [ContactDetail, setContact] = useState([])

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const data = await contactTable.getContacts();
                // console.log('data',data);

                setContact(data);
            } catch (error) {

                console.error('Error fetching contacts:', error.message);
            }
        };

        fetchContacts();
    }, [id]);



    const handleDelete = async (id) => {
        const conformDelete = window.confirm('Are You Sure Want To Delete Contact')
        if (conformDelete) {
            try {
                await contactTable.contactDetete(id)
                toast.success('contact deleted')
                setContact(prevContacts => prevContacts.filter(contact => contact._id !== id));
            } catch (error) {
                toast.error('Failed to delete contact');
                console.error(error);
            }
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const contactPerPage = 5;

    // Calculate the indices for slicing the contacts array
    const indexOfTheLastContact = currentPage * contactPerPage;
    const indexOfTheFirstContact = indexOfTheLastContact - contactPerPage;

    // Slice the contacts array to get only the contacts for the current page
    const currentContact = ContactDetail.slice(indexOfTheFirstContact, indexOfTheLastContact);

    // Calculate total pages
    const totalPages = Math.ceil(ContactDetail.length / contactPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="card card-hover">
                <div className="container py-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-5">
                                <h2 className="fw-bold">Contact Form</h2>
                                <p className="mb-0">All Contact Details From Contact Form</p>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">First</th>
                                            <th scope="col">Last</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentContact.map((contact, index) => (
                                            <tr key={contact._id}>
                                                <th>{(currentPage - 1) * contactPerPage + index + 1}</th>
                                                <th scope="row">{moment(contact.createdAt).utc().format('ll (ddd)')}</th>
                                                <th scope="row">{contact.firstname}</th>
                                                <th>{contact.lastname}</th>
                                                <th>
                                                    <Link to={`/admin/dashboard/contact-form/${contact._id}`}><i className="bi bi-eye-fill 
                                                    text-primary me-2"></i></Link>
                                                    <button onClick={() => handleDelete(contact._id)} type='button' className='btn'><i className="bi bi-trash3-fill 
                                                    text-danger me-2"></i></button>
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Pagination */}
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-sm">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li
                                    key={index + 1}
                                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                    aria-current={currentPage === index + 1 ? 'page' : undefined}
                                >
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default ContactForm;
