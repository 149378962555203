import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/adminLogin.png';
import reviewFrom from '../../../Services/customerServices';
import moment from 'moment'

const ViewReviews = () => {
    const { id } = useParams(); // `id` will be undefined if we're adding a new review
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [photo, setFile] = useState(null);

    const [review, setReview] = useState({
        name: '',
        companyName: '',
        designation: '',
        rating: '',
        message: '',
        status: '',
        featured: '',
        photo: '' // This can be used to store the uploaded photo URL if needed
    });


    useEffect(() => {
        if (id) {
            // Fetch existing review data if `id` is present (update mode)
            const fetchData = async () => {
                try {
                    const data = await reviewFrom.getReview(id);
                    setReview(data[0]);
                } catch (err) {
                    setError('Failed to fetch review data');
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        } else {
            // If there's no `id`, we're in add mode, so no need to fetch data
            setLoading(false);
        }
    }, [id]);


    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }


    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img
                        src={logo}
                        className="img-fluid"
                        width={400}
                        alt="Customer Review"
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row">
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={review.name}

                                        className="form-control"
                                        placeholder="Name"
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companyName">Company Name</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        name="companyName"
                                        value={review.companyName}

                                        className="form-control"
                                        placeholder="Company Name"
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="designation">Designation</label>
                                    <input
                                        type="text"
                                        id="designation"
                                        name="designation"
                                        value={review.designation}

                                        className="form-control"
                                        placeholder="Designation"
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="rating">Rating</label>
                                    <select
                                        id="rating"
                                        name="rating"
                                        value={review.rating}

                                        className="form-control"
                                        disabled
                                    >
                                        <option value="">Select Rating</option>
                                        <option value="1">1 Star</option>
                                        <option value="2">2 Star</option>
                                        <option value="3">3 Star</option>
                                        <option value="4">4 Star</option>
                                        <option value="5">5 Star</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        className="form-control"
                                        rows="5"
                                        placeholder="Message"
                                        value={review.message}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="status">Status</label>
                                    <select
                                        id="status"
                                        name="status"
                                        value={review.status}

                                        className="form-control"
                                        disabled
                                    >


                                        <option value="Published">Published</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="featured">Feactured</label>
                                    <select
                                        id="featured"
                                        name="featured"
                                        value={review.featured}
                                        className="form-control"
                                        disabled

                                    >
                                        <option value="Partner">Partner</option>
                                        <option value="Not">Not Partner</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="photo">Photo/Logo </label>
                                    <img src={review.photo} alt="reviewer Photo Or Logo" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="photo">CreatedAt </label>
                                    <input type="text" className='form-control' value={moment(review.createdAt).utc().format('llll')} readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="photo">UpdatedAt </label>
                                    <input type="text" className='form-control' value={moment(review.updatedAt).utc().format('llll')} readOnly />
                                </div>
                                <div className="col-12">
                                    <Link to="/admin/dashboard/customer-review" className="btn btn-secondary ms-2">Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewReviews;
