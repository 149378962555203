import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import girl from '../../assets/images/girl4.png';
import Clients from '../Clients/Clients';
import ContactServices from '../../Services/ContactServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef();

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        try {
            //console.log('handle submition', firstname, lastname, email, phone, message);

            const data = await ContactServices.contactFrom(firstname, lastname, email, phone, message);
            await sendEmail(e)
            // console.log("Data Check", data);

            toast.success('Your contact form was submitted successfully. We will contact you soon.');
        } catch (error) {
            console.error('Error submitting contact form:', error);
            toast.error('Failed to submit contact form. Please try again.');
        }
    };

    const sendEmail = async (e) => {
        // e.preventDefault();

        if (!firstname || !lastname || !email || !phone || !message) {
            toast.error('Please fill in all fields.');
            return;
        }

        // await handleFormSubmit();
        try {
            await emailjs.sendForm('service_3ebezp4', 'template_k7kk3gy', form.current, 'HIM93P25QsLGF7Duf');
            e.target.reset();
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setMessage('');
            e.target.reset();
            toast.success('Email Message sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Email Failed to send message. Please try again.');
        }
    };




    return (
        <>
            <Header />
            <div className="py-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="mb-4 mb-lg-0">
                                <img src={girl} alt="..." className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">
                            <div className="ps-lg-7">
                                <span className="text-primary ls-md text-uppercase fw-semibold">Have a Project in mind?</span>
                                <h2 className="display-4 mt-4 mb-3 fw-bold">Ready To Build Your Next Project</h2>
                                <h3>We are a digital agency that helps brands to achieve their business outcomes. We see technology as a tool to create amazing things </h3>
                                <div className="mt-5">
                                    <p>Navigate your way to connect with us. Each step leads you closer to personalized support and expert guidance from the MindScape Technologies team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
                            <span className="fs-4 text-warning ls-md text-uppercase fw-semibold">Have a Project in mind?</span>
                            <h2 className="display-3 mt-4 mb-3 fw-bold">Contact Us</h2>
                            <p className="lead px-lg-8 mb-6">Learn more about MindScape Technologies and how we're supercharging progress for our clients, our people, and our communities.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-8">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card card-hover">
                                <div className="d-flex justify-content-between align-items-center p-4">
                                    <div className="d-flex">
                                        <a href=""><i className="bi bi-telephone-inbound-fill" style={{ fontSize: "32px" }}></i></a>
                                        <div className="ms-3">
                                            <h4 className="mb-1">
                                                <a href="" className="text-inherit">Call Now</a>
                                            </h4>
                                            <p className="mb-0 fs-6">
                                                <span className="me-2">
                                                    <span className="text-dark fw-medium">+91</span>
                                                </span>
                                                <span>
                                                    <span className="text-dark fw-medium" style={{ fontWeight: 'bolder' }}> 70389 07594</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card card-hover">
                                <div className="d-flex justify-content-between align-items-center p-4">
                                    <div className="d-flex">
                                        <a href=""><i className="bi bi-envelope-at-fill" style={{ fontSize: "32px" }}></i></a>
                                        <div className="ms-3">
                                            <h4 className="mb-1">
                                                <a href="" className="text-inherit">Email</a>
                                            </h4>
                                            <p className="mb-0 fs-6">
                                                <span>
                                                    <span className="text-dark fw-medium" style={{ fontWeight: 'bolder' }}> hello@mindscapetechnologies.in</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card card-hover">
                                <div className="d-flex justify-content-between align-items-center p-4">
                                    <div className="d-flex">
                                        <a href=""><i className="bi bi-geo-alt-fill" style={{ fontSize: "32px" }}></i></a>
                                        <div className="ms-3">
                                            <h4 className="mb-1">
                                                <a href="" className="text-inherit">Pune</a>
                                            </h4>
                                            <p className="mb-0 fs-6">
                                                <span className="me-2">
                                                    <span className="text-dark fw-medium">India</span>
                                                </span>
                                                <span>
                                                    <span className="text-dark fw-medium" style={{ fontWeight: 'bolder' }}> To-Do Coworking Space Wakad, Pune</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card card-hover">
                                <div className="d-flex justify-content-between align-items-center p-4">
                                    <div className="d-flex">
                                        <a href=""><i className="bi bi-geo-alt-fill" style={{ fontSize: "32px" }}></i></a>
                                        <div className="ms-3">
                                            <h4 className="mb-1">
                                                <a href="" className="text-inherit">Wardha</a>
                                            </h4>
                                            <p className="mb-0 fs-6">
                                                <span className="me-2">
                                                    <span className="text-dark fw-medium">India</span>
                                                </span>
                                                <span>
                                                    <span className="text-dark fw-medium" style={{ fontWeight: 'bolder' }}> Near Canara Bank Barbadi MIDC, Wardha</span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card card-hover">
                                <div className="d-flex justify-content-between align-items-center p-4">
                                    <div className="d-flex">
                                        <a href="">
                                            <i className="bi bi-share-fill" style={{ fontSize: "32px" }}></i>
                                        </a>
                                        <div className="ms-3">
                                            <h4 className="mb-1">
                                                <a href="" className="text-inherit">Follow Us On Social Media</a>
                                            </h4>
                                            <p className="mb-0 fs-6">
                                                <span className="text-dark fw-medium" style={{ fontSize: '20px' }}>
                                                    <a href="https://www.linkedin.com/company/mindscape-tech/" target="_blank" rel="noopener noreferrer" className="me-3">
                                                        <i className="bi bi-linkedin"></i>
                                                    </a>
                                                    <a href="https://x.com/mindscapeindia" target="_blank" rel="noopener noreferrer" className="me-3">
                                                        <i className="bi bi-twitter-x"></i>
                                                    </a>
                                                    <a href="https://www.instagram.com/mindscapetechnologies.in" target="_blank" rel="noopener noreferrer">
                                                        <i className="bi bi-instagram"></i>
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <form ref={form} onSubmit={handleFormSubmit} className="row">
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="firstname">First Name</label>
                                            <input
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                className="form-control"
                                                value={firstname}
                                                placeholder="First Name"
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="lastname">Last Name</label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                className="form-control"
                                                placeholder="Last Name"
                                                value={lastname}
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-6">
                                            <label className="form-label" htmlFor="phone">Phone Number</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                required
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 col-12 col-md-12">
                                            <label className="form-label" htmlFor="message">Message</label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                className="form-control"
                                                rows="5"
                                                placeholder="Message"
                                                required
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Clients />
            <Footer />
            <ToastContainer />
        </>
    );
};

export default Contact;
