import React from 'react'
import logo from '../../assets/images/MindScape.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()
    return (
        <>
            <footer className="pt-lg-10 pt-5 footer bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">

                            <div className="mb-4">
                                <img src={logo} alt="" width={135} className="logo-inverse " />
                                <div className="mt-4">
                                    <p>We are a digital agency that helps brands to achieve their business outcomes. We see technology as a tool to create amazing things.</p>

                                    <div className="fs-4 mt-4">
                                        <a href="https://www.linkedin.com/company/mindscape-tech/" className="bi bi-linkedin fs-4 text-muted me-2" target='_blank'></a>
                                        <a href="https://x.com/mindscapeindia" className="bi bi-twitter-x text-muted me-2"></a>
                                        <a href="https://www.instagram.com/mindscapetechnologies.in?igsh=YjN4cnJsNmxhd28z" target='_blank' className="bi bi-instagram text-muted "></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-2 col-md-3 col-6">
                            <div className="mb-4">

                                <h3 className="fw-bold mb-3">Company</h3>
                                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                                    <li><Link to={'/about'} className="nav-link">About</Link></li>
                                    <li><Link to={'/career'} className="nav-link">Careers</Link></li>
                                    <li><Link to={'/contact'} className="nav-link">Contact</Link></li>
                                    <li><a href="https://workspace.mindscapetechnologies.in/" target='_blank' className="nav-link">Help and Support</a></li>
                                    <li><a href="https://credentials.mindscapetechnologies.in/" target='_blank' className="nav-link">Verify Certificate</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6">
                            <div className="mb-4">

                                <h3 className="fw-bold mb-3">Services</h3>
                                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">

                                    <li><Link to={'/services/Web-development/'} className="nav-link">Web Development</Link></li>
                                    <li><Link to={'/service/shopify-development/'} className="nav-link">Shopify Development
                                    </Link></li>
                                    <li><Link to={'/service/wordpress-development/'} className="nav-link">WordPress Development</Link></li>
                                    <li><Link to={'/services/ui-ux/'} className="nav-link">UI/UX Service</Link></li>
                                    <li><Link to={'/services/App-development/'} className="nav-link">App Development</Link></li>
                                    <li><Link to={'/services/customization-services/'} className="nav-link">Customization Service</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12">

                            <div className="mb-4">
                                <h3 className="fw-bold mb-3">Get in touch</h3>
                                <p>To-Do Coworking Space Wakad, Pune</p>
                                <p className="mb-1">Email: <a href="mailto:hello@mindScapetechnologies.in">hello@mindScapetechnologies.in</a></p>
                                <p>Phone: <span className="text-dark fw-semibold">+91 (788) 746 5779</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center g-0 border-top py-2 mt-6">
                        <div className="col-lg-4 col-md-5 col-12">
                            <span>
                                © {' '}
                                <span id="copyright">
                                    {year} {' '}
                                </span>
                                MindScape Technologies. All Rights Reserved
                            </span>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
                            <nav className="nav nav-footer">
                                <Link className="nav-link ps-0" to={'/privacy-policy/'}>Privacy Policy</Link>
                                <Link className="nav-link px-2 px-md-3" to={'/cockies-policy/'}>Cookie Notice  </Link>
                                <Link className="nav-link" to={'/terms-policy/'}>Terms of Use</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
