import axios from "axios";

const apiUrl = 'http://localhost:3001/api/admin/'

const adminTable = async () => {
    try {
        const response = await axios.get(apiUrl + 'table');
       // console.log(response);
        return response.data
    } catch (error) {
        console.error('error', error)
        console.error('Error Admin table:', error.response ? error.response.data : error.message);
        throw error;
    }
}

const adminUser = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}user/${id}`)
        //console.log('response form services', response);
        return response.data

    } catch (error) {
        console.error('error', error)
        console.error('Error Admin user Find:', error.response ? error.response.data : error.message);
        throw error;
    }
}


const adminDelete = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}user/${id}`)
        console.log('Admin Delete Response', response);
        return response.data
    } catch (error) {
        console.error('error', error)
        console.error('Error Admin user delete:', error.response ? error.response.data : error.message);
        throw error;
    }
}



export default {
    adminTable, adminUser, adminDelete
}