import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Admin/Pages/Login';
import Dashboard from '../Components/Admin/Dashboard/Dashboard';
import Customerform from '../Components/Admin/Form/Customerform';
import MainLayout from '../Components/Admin/Dashboard/MainLayout';
import ContactForm from '../Components/Admin/Pages/ContactForm';
import CustomerReview from '../Components/Admin/Pages/CustomerReview';
import Clients from '../Components/Admin/Pages/Clients';
import Job from '../Components/Admin/Pages/Job';
import Leads from '../Components/Admin/Pages/Leads';
import ProductList from '../Components/Admin/Test/ProductList';
import Admin from '../Components/Admin/Pages/Admin';
import CustomerReviewFrom from '../Components/Admin/Form/CustomerReview';
import ClientForm from '../Components/Admin/Form/ClientForm';
import LeadForm from '../Components/Admin/Form/LeadForm';
import AdminForm from '../Components/Admin/Form/AdminForm';
import ViewReviews from '../Components/Admin/Form/ViewReviews';
import CreateLead from '../Components/Admin/Form/CreateLead';
import PublicRoute from '../Services/PublicRouter';
import ProtectedRoute from '../Services/ProtectedRoute';
import ChangePassword from '../Components/Admin/Pages/ChangePassword';

function AppRoutes({ setAuth }) {
    return (
        <Routes>
            <Route
                path='login'
                element={
                    <PublicRoute>
                        <Login setAuth={setAuth} />
                    </PublicRoute>
                }
            />
            <Route
                path='/dashboard'
                element={
                    <ProtectedRoute>
                        <MainLayout />
                    </ProtectedRoute>
                }
            >
                <Route index element={<Dashboard />} />
                <Route path="contact-form" element={<ContactForm />} />
                <Route path="contact-form/:id" element={<Customerform />} />
                <Route path="customer-review" element={<CustomerReview />} />
                <Route path="customer-review/:id" element={<CustomerReviewFrom />} />
                <Route path="view-review/:id" element={<ViewReviews />} />
                <Route path="update-review/" element={<CustomerReviewFrom />} />
                <Route path="clients" element={<Clients />} />
                <Route path="clients/:id" element={<ClientForm />} />
                <Route path="job-post" element={<Job />} />
                <Route path="leads" element={<Leads />} />
                <Route path="create-lead" element={<CreateLead />} />
                <Route path="leads/:id" element={<LeadForm />} />
                <Route path="leads/update-lead/:id" element={<CreateLead />} />
                <Route path="admin" element={<Admin />} />
                <Route path="admin/:id" element={<AdminForm />} />
                <Route path="change-password" element={<ChangePassword />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
