import React from 'react'
import DashHeader from './DashHeader'
import Footer from '../Pages/Footer'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import './style.css';

const MainLayout = () => {
    return (
        <>
            <DashHeader />
            <div className="dashboard-container">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        {/* Main Content */}
                        <div className="col-md-9 col-lg-10">
                            <div className="tab-content" id="v-pills-tabContent">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MainLayout
