import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import avtar8 from '../../assets/images/avatar-8.jpg';
import avtar9 from '../../assets/images/avatar-9.jpg';
import avtar10 from '../../assets/images/avatar-10.jpg';
import avtar11 from '../../assets/images/avatar-11.jpg';
import avtar12 from '../../assets/images/avatar-12.jpg';
import girl from '../../assets/images/girl2.png'
import office from '../../assets/images/office3.jpg'
import Inclusion from '../../assets/images/Inclusion.avif'
import Integrity from '../../assets/images/Integrity.avif'
import ValueCreation from '../../assets/images/Value Creation.jpg'
import SocialResponsibility from '../../assets/images/Social Responsibility.avif'
import Peoplecentricity from '../../assets/images/People-centricity.jpg'
import Clients from '../Clients/Clients';
import Services from '../Services/Services';
import Reaview from '../Review/Review';




const About = () => {
    return (
        <>
            <Header />

            <div className="py-2 py-lg-10">
                <div className="container">
                    <div className="row mb-6 align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="row align-items-center ">
                                <div className="col-xl-6 col-lg-7 col-md-12 col-12 order-1 text-center text-lg-start ">

                                    <span className="text-primary mb-3 d-block text-uppercase fw-semibold ls-xl">Our team comes with the experience and knowledge</span>
                                    <h2 className="mb-2 display-4 fw-bold mb-3">Welcome To <span className="text-primary">MindScape Technologies</span></h2>
                                    <p className="fs-3 pe-6">We are more than just a tech company; we are a passionate community of tech enthusiasts, educators, and problem solvers. We believe that the world of technology is boundless, and we are committed to unlocking its limitless potential for individuals and organizations alike.</p>

                                    <hr className="my-5" />

                                    <div className="row">
                                        <div className="col-sm mb-3 mb-lg-0">
                                            <h2 className="h1 fw-bold mb-0 ls-xs">120+</h2>
                                            <p className="mb-0">Family</p>
                                        </div>
                                        <div className="col-lg-5 col-sm mb-3 mb-lg-0">
                                            <h2 className="h1 fw-bold mb-0 ls-xs">07+</h2>
                                            <p className="mb-0">Products</p>
                                        </div>
                                        <div className="col-sm mb-3 mb-lg-0">
                                            <h2 className="h1 fw-bold mb-0 ls-xs">32+</h2>
                                            <p className="mb-0">Project's</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center ">
                                    <img src={office} alt="MindScape Technologie Office" className="img-fluid rounded" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Services />
                <section className="py-6">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-12 px-lg-8 mb-8 mt-6">
                                <span className="text-uppercase text-primary fw-semibold ls-md">About Us</span>
                                <h2 className="h1 fw-bold mt-3">Our Core Values</h2>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-12">
                                <div className="card mb-4 card-hover border">
                                    <a href="#!">
                                        <img src={Integrity} alt="Integrity" className="img-fluid w-100 rounded-top-3" />
                                    </a>
                                    <div className="card-body">
                                        <h4 className="mb-3">
                                            <a href="#!" className="text-inherit">Integrity</a>
                                        </h4>
                                        <div className="d-flex align-items-center mb-5 lh-1">
                                            <div>
                                                <p className="text-dark fw-medium">
                                                    Upholding the highest ethical standards, we are unwavering in our commitment to always doing the right thing.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-12">
                                <div className="card mb-4 card-hover border">
                                    <a href="#!">
                                        <img src={Inclusion} alt="Inclusion" className="img-fluid w-100 rounded-top-3" />
                                    </a>
                                    <div className="card-body">
                                        <h4 className="mb-3">
                                            <a href="#!" className="text-inherit">Inclusion</a>
                                        </h4>
                                        <div className="d-flex align-items-center mb-5 lh-1">
                                            <div>
                                                <p className="text-dark fw-medium">
                                                    We foster an environment where everyone can succeed and be their authentic selves, ensuring equal access and opportunities for all.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-12">
                                <div className="card mb-4 card-hover border">
                                    <a href="#!">
                                        <img src={ValueCreation} alt="Value Creation" className="img-fluid w-100 rounded-top-3" />
                                    </a>
                                    <div className="card-body">
                                        <h4 className="mb-3">
                                            <a href="#!" className="text-inherit">Value Creation</a>
                                        </h4>
                                        <div className="d-flex align-items-center mb-5 lh-1">
                                            <div>
                                                <p className="text-dark fw-medium">
                                                    Dedicated to our clients' success, we tirelessly go the extra mile to deliver on commitments and uncover new growth opportunities.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-12">
                                <div className="card mb-4 card-hover border">
                                    <a href="#!">
                                        <img src={Peoplecentricity} alt="People-centricity" className="img-fluid w-100 rounded-top-3" />
                                    </a>
                                    <div className="card-body">
                                        <h4 className="mb-3">
                                            <a href="#!" className="text-inherit">People-centricity</a>
                                        </h4>
                                        <div className="d-flex align-items-center mb-5 lh-1">
                                            <div>
                                                <p className="text-dark fw-medium">
                                                    We inspire our team to "find their spark," empowering them to shape their careers, innovate, and bring forth ideas of all sizes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-12">
                                <div className="card mb-4 card-hover border">
                                    <a href="#!">
                                        <img src={SocialResponsibility} alt="Social Responsibility" className="img-fluid w-100 rounded-top-3" />
                                    </a>
                                    <div className="card-body">
                                        <h4 className="mb-3">
                                            <a href="#!" className="text-inherit">Social Responsibility</a>
                                        </h4>
                                        <div className="d-flex align-items-center mb-5 lh-1">
                                            <div>
                                                <p className="text-dark fw-medium">
                                                    We actively give back to our communities, prioritizing actions that benefit both the planet and the places where we live and work.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Reaview />
            </div>
            <div className=" py-5">
                <div className="container">
                    <div className="gallery">
                        <figure className="gallery__item gallery__item--1 mb-0">
                            <img src={avtar8} alt="Gallery image 1" className="gallery__img rounded-3" />
                        </figure>

                        <figure className="gallery__item gallery__item--2 mb-0">
                            <img src={avtar9} alt="Gallery image 2" className="gallery__img rounded-3" />
                        </figure>

                        <figure className="gallery__item gallery__item--3 mb-0">
                            <img src={avtar10} alt="Gallery image 3" className="gallery__img rounded-3" />
                        </figure>

                        <figure className="gallery__item gallery__item--4 mb-0">
                            <img src={avtar11} alt="Gallery image 4" className="gallery__img rounded-3" />
                        </figure>

                        <figure className="gallery__item gallery__item--5 mb-0">
                            <img src={avtar12} alt="Gallery image 5" className="gallery__img rounded-3" />
                        </figure>
                        <figure className="gallery__item gallery__item--6 mb-0">
                            <img src={girl} alt="Gallery image 6" className="gallery__img rounded-3" />
                        </figure>
                    </div>
                </div>

            </div>
            <Clients />
            <Footer />
        </>
    )
}

export default About
