import { useEffect, useState } from 'react';
import logo from '../../../assets/images/adminLogin.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Services from '../../../Services/LeadServices'
import DatePicker from 'react-datepicker'


const CreateLead = () => {

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { id } = useParams();
    const [lead, setLead] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
        status: '',
        source: '',
        companyname: '',
        agent: '',
        subject: '',
        city: '',
        field: '',
        leadDate: ''
    });

    useEffect(() => {
        if (id) {
            const fetchData = async (id) => {
                try {
                    const data = await Services.getLead(id);
                    console.log('Getting Lead for update', data);
                    setLead(data);
                } catch (err) {
                    console.error('Failed to fetch lead data', err);
                    toast.error('Failed to fetch lead data');
                } finally {
                    setLoading(false);
                }
            };
            fetchData(id);
        } else {
            setLoading(false);
        }
    }, [id]);

    const navigate = useNavigate();



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLead({
            ...lead,
            [name]: value
        });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess('');

        const formData = new FormData();
        for (const key in lead) {
            formData.append(key, lead[key]);
        }

        try {
            if (id) {
                await Services.updateLead(id, formData);
                toast.success('Lead Updated Successfully');
            } else {
                const data = await Services.createLead(lead.agent, lead.city, lead.companyname, lead.email, lead.field, lead.firstname, lead.lastname, lead.leadDate, lead.message, lead.phone, lead.source, lead.status, lead.subject);
                console.log('check jsx data', data);

                toast.success('New Lead Added Successfully');
            }
            navigate('/admin/dashboard/leads');

        } catch (error) {
            console.error('Error', error);
            toast.error('Failed to save lead. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }
    return (
        <>
            <div className="row">
                <div className='col-12 col-md-6'>
                    <img src={logo} className='img-fluid' width={400} alt="" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row" onSubmit={handleOnSubmit}>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="firstname">First Name</label>
                                    <input type="text" id="firstname" name="firstname" onChange={handleInputChange} value={lead.firstname} className="form-control" placeholder="First Name" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="lastname">Last Name</label>
                                    <input type="text" id="lastname" name="lastname" value={lead.lastname} onChange={handleInputChange} className="form-control" placeholder="Last Name" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" className="form-control" value={lead.email} onChange={handleInputChange} placeholder="Email" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="phone">Phone Number</label>
                                    <input type="tel" id="phone" value={lead.phone} onChange={handleInputChange} name="phone" className="form-control" placeholder="Phone Number" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="source">Source</label>
                                    <select
                                        id="source"
                                        name="source"
                                        value={lead.source}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="*">Select Source</option>
                                        <option value="google">Google</option>
                                        <option value="socialMedia">Social Media</option>
                                        <option value="reference">Reference</option>
                                        <option value="ads">Ad's</option>
                                        <option value="website">Website</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="status">Status</label>
                                    <select
                                        id="status"
                                        name="status"
                                        value={lead.status}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="*">Select Status</option>
                                        <option value="New">New</option>
                                        <option value="Active">Active</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Cancel">Cancel</option>
                                        <option value="Complete">Complete</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companyname">Company Name</label>
                                    <input type="text" id="companyname" name="companyname" onChange={handleInputChange} value={lead.companyname} className="form-control" placeholder="Company Name" />

                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="city">City</label>
                                    <input type="text" id="city" name="city" className="form-control" onChange={handleInputChange} value={lead.city} placeholder="City" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="field">Field</label>
                                    <select
                                        id="field"
                                        name="field"
                                        value={lead.field}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="*">Select Field</option>
                                        <option value="Development">Development</option>
                                        <option value="DigitalMarketing">Digital Marketing</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="agent">Agent</label>
                                    <input type="text" id="agent" onChange={handleInputChange} value={lead.agent} name="agent" className="form-control" placeholder="Agent" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="Subject">Subject</label>
                                    <input type="text" id="subject" onChange={handleInputChange} value={lead.subject} name="subject" className="form-control" placeholder="Subject" />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="Date">Date</label>
                                    <input type="date" id="leadDate" name="leadDate" onChange={handleInputChange} value={lead.leadDate} className="form-control" />
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="message">Message</label>
                                    <textarea id="message" onChange={handleInputChange} value={lead.message} name="message" className="form-control" rows="5" placeholder="Message" ></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {id ? 'Update Lead' : 'Add Lead'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default CreateLead