import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import logo from '../../../assets/images/adminLogin.png'
import Services from '../../../Services/adminServices'
import moment from 'moment'


const AdminForm = () => {
    const { id } = useParams()

    const [Admin, setAdminDetails] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fatchData = async () => {
            try {
                const data = await Services.adminUser(id)
                setAdminDetails(data)
            } catch (error) {
                console.error('Error fetching admin user:', error.message);
                setError('Failed to fetch admin User');
            } finally {
                setLoading(false);
            }

        }
        fatchData()
    }, [id])

    if (loading) {
        return <h2>Loading...</h2>;
    }

    if (error) {
        return <h2>{error}</h2>;
    }

    if (!Admin) {
        return <h2>Contact not found!</h2>;
    }

    return (
        <>
            <div className="row">
                <div className='col-12 col-md-6'>
                    <img src={logo} className='img-fluid' width={400} alt="" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row">
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="firstname">First Name</label>
                                    <input type="text" id="firstname" name="firstname" value={Admin.firstname} className="form-control" placeholder="First Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="lastname">Last Name</label>
                                    <input type="text" id="lastname" value={Admin.lastname} name="lastname" className="form-control" placeholder="Last Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" value={Admin.email} className="form-control" placeholder="Email" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="phone">Phone Number</label>
                                    <input type="tel" id="phone" name="phone" value={Admin.phone} className="form-control" placeholder="Phone Number" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="username">Username</label>
                                    <input type="text" id="sousernameurce" name="username" value={Admin.username} className="form-control" placeholder="username" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="status">Status</label>
                                    <input type="text" id="status" name="status" value={Admin.status} className="form-control" placeholder="status" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="password">Password</label>
                                    <input type="text" id="password" name="password" value={Admin.password} className="form-control" placeholder="password" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="role">Role</label>
                                    <input type="text" id="role" name="role" value={Admin.role} className="form-control" placeholder="role" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-12">
                                    <label className="form-label" htmlFor="role">Profile Photo</label>
                                    <img src={logo} width={100} className='rounded-circle' alt="User Avatar" />

                                    <input type="file" id="file" name="file" className="form-control" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="CreatedAt">CreatedAt</label>
                                    <input type="text" id="CreatedAt" name="CreatedAt" value={moment(Admin.createdAt).utc().format('LLL')} className="form-control" placeholder="CreatedAt" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="UpdatedAt">UpdatedAt</label>
                                    <input type="text" id="UpdatedAt" name="UpdatedAt" value={moment(Admin.updatedAt).utc().format('LLL')}  className="form-control" placeholder="UpdatedAt" readOnly />
                                </div>
                                <div className="col-12">
                                    <Link to={'/admin/dashboard/admin'} className="btn btn-primary">Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AdminForm
