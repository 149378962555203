import React from 'react'
import Stats from '../Stats/Stats'
import Services from '../Services/Services'
import Reaview from '../Review/Review'
import Clients from '../Clients/Clients'
import Footer from '../Footer/Footer'
import feacture from '../../assets/images/feature-img4.png'
import Header from '../Header/Header'
import WorkFlow from '../WorkFlow/WorkFlow'

const UIUX = () => {
  return (
 
       <>
            <Header />
            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="mb-4 mb-lg-0">
                                <img src={feacture} alt="..."
                                    className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">

                            <div className="ps-lg-7">
                                <span className=" text-primary ls-md text-uppercase fw-semibold">Our Services</span>
                                <h2 className="display-4 mt-4 mb-3 fw-bold">Ui/Ux</h2>
                                <h3>At our core, we provide bespoke UI/UX services tailored to our clients' unique needs, ensuring every interface is visually captivating, user-centric, and intuitive. </h3>
                                <div className="mt-5 row">

                                    <div className="col">
                                        <ul className="list-unstyled fs-4 fw-medium">
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Customize Design Solution</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i> User-Centric Approach</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Latest Trends and Technologies</li>

                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Functional and Accessible</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>User Friendly</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Business Growth and User Satisfaction</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Stats />
            <Services />
            <WorkFlow/>
            <Reaview />

            <Clients />
            <Footer />
        </>
  )
}

export default UIUX
