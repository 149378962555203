import React from 'react'
import view from '../../assets/Tech/logo-1-1.webp'
import nextjs from '../../assets/Tech/nextjs.webp'
import js from '../../assets/Tech/Java-Script-Logo.webp'
import jquary from '../../assets/Tech/JQuery-Logo.webp'
import Html from '../../assets/Tech/html-1-1.webp'
import Css from '../../assets/Tech/css-3-1.webp'
import mysql from '../../assets/Tech/mysql.webp'
import telwine from '../../assets/Tech/Tailwind-CSS-Logo.webp'
import svg from '../../assets/Tech/SVG-logo.webp'
import PHP from '../../assets/Tech/PHP-Logo.webp'
import Bootstap from '../../assets/images/bootstrap.png'
import feacture from '../../assets/images/feature-img.svg'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Clients from '../Clients/Clients'
import Reaview from '../Review/Review'
import Services from '../Services/Services'
import Stats from '../Stats/Stats'
import WorkFlow from '../WorkFlow/WorkFlow'

const WordPress = () => {
    return (
        <>
            <Header />
            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="mb-4 mb-lg-0">
                                <img src={feacture} alt="..."
                                    className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">

                            <div className="ps-lg-7">
                                <span className=" text-primary ls-md text-uppercase fw-semibold">Our Services</span>
                                <h2 className="display-4 mt-4 mb-3 fw-bold">WordPress Development</h2>
                                <h3>Our WordPress development services offer a comprehensive solution for establishing an online presence on a robust and versatile platform. We ensure your website looks great on all devices and provides a seamless experience for your visitors.</h3>
                                <div className="mt-5 row">

                                    <div className="col">
                                        <ul className="list-unstyled fs-4 fw-medium">
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Customize Solution</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i> Responsive Design</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Cutting-edge Technologies</li>

                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>SEO Optimization </li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>User Friendly</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Protected & Secure</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Comprehensive Support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="py-10">
                <div class="container">
                    <h2 className="h1 fw-bold mt-3">Our Modern Full-Stack Tech Built
                        For Performance.
                    </h2>
                    <div class="row">
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={Html} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1"> HTML5</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={Css} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">CSS3</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={view} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">View Js</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-lg border-bottom border-end-md ">

                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={js} alt="" />
                                </div>

                                <div class="lh-1">
                                    <h2 class="mb-1">JavaScript</h2>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6 col-lg-2 border-top-lg border-bottom ">

                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={jquary} alt="" />
                                </div>

                                <div class="lh-1">
                                    <h2 class="mb-1">JQuary</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-lg border-bottom ">

                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={svg} alt="" />
                                </div>

                                <div class="lh-1">
                                    <h2 class="mb-1">SVG</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={Bootstap} width={60} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1"> BootStrap</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={nextjs} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">NextJs</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={telwine} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">Tailwine Css</h2>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={mysql} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">MySql</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 border-top-md border-bottom border-end-md ">
                            <div class="py-7 text-center">
                                <div class="mb-3">
                                    <img src={PHP} alt="" />
                                </div>
                                <div class="lh-1">
                                    <h2 class="mb-1">PHP</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Stats />
            <Services />
            <WorkFlow />
            <Reaview />
            <Clients />
            <Footer />
        </>
    )
}

export default WordPress
