import React from 'react'
import logo from '../../assets/images/MindScape.png'
import './style.css'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div>

            <nav className="navbar navbar-expand-lg ">
                <div className="container px-0">
                    <Link className="navbar-brand" to={'/'}><img src={logo}
                        alt="MindScape Technologies" width={150} /></Link>
                    <div className="d-flex align-items-center order-lg-3 ms-lg-3">
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-default5"
                            aria-controls="navbar-default5" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar top-bar mt-0"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse" id="navbar-default5">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page"
                                to={'/'}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/about'}>About Us</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#"
                                    id="navbarDropdown" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"
                                    data-bs-display="static">
                                    Services
                                </a>
                                <ul className="dropdown-menu dropdown-menu-arrow" aria-labelledby="navbarDropdown">
                                    <li>
                                        <li>
                                            <Link className="dropdown-item" to={'/services/Web-development/'}>
                                                Web Solution
                                            </Link>
                                        </li>

                                        <li>
                                            <Link className="dropdown-item" to={'/services/App-development/'}>
                                                Mobile App
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/services/ui-ux/'} className="dropdown-item">
                                                UI/UX Design
                                            </Link>
                                        </li>
                                       
                                    </li>
                                    <li>
                                        <Link to={'/service/wordpress-development/'} className="dropdown-item">
                                            WordPress Development
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/service/shopify-development/'} className="dropdown-item">
                                            Shopify Development
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/services/customization-services/'} className="dropdown-item">
                                            Customization Service
                                        </Link>
                                    </li>
                                </ul>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/career'}>Career</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/contact'}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header
