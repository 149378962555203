import React from 'react'
import logo from '../../../assets/images/MindScape.png'

const Header = () => {
    return (
        <nav className="navbar navbar-expand ">
            <div className="container px-0">
                <div className="d-flex align-items-center">
                    <a className="navbar-brand me-4" href="/"><img src={logo}
                        alt="MindScape Technologies" width={150} /></a>
                </div>
            </div>
        </nav>
    )
}

export default Header
