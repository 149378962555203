import React from 'react'
import logo from '../../../assets/images/adminLogin.png'
import { Link, useParams } from 'react-router-dom';

const ClientForm = () => {
    const { id } = useParams();

    const ClientData = [
        { id: 1, companyName: 'Jonh Yec', companyLogo: logo, status: 'Pending' },
        { id: 2, companyName: 'Acme Corp', companyLogo: logo, status: 'Published' },
        { id: 3, companyName: 'Tech Innovations', companyLogo: logo, status: 'Rejected' },
        { id: 4, companyName: 'Bright Solutions', companyLogo: logo, status: 'Pending' },
        { id: 5, companyName: 'Global Ventures', companyLogo: logo, status: 'Published' },
        { id: 6, companyName: 'Creative Minds', companyLogo: logo, status: 'Rejected' },
        { id: 7, companyName: 'NextGen Industries', companyLogo: logo, status: 'Pending' },
        { id: 8, companyName: 'Pioneer Technologies', companyLogo: logo, status: 'Published' },
        { id: 9, companyName: 'Future Enterprises', companyLogo: logo, status: 'Rejected' },
        { id: 10, companyName: 'Vertex Systems', companyLogo: logo, status: 'Pending' }
    ];

    const Clients = ClientData.find(c => c.id === parseInt(id))
    if (!Clients) {
        return <h2>Client not found!</h2>;

    }
    return (
        <>
            <div className="row">
                <div className='col-12 col-md-6'>
                    <img src={logo} className='img-fluid' width={400} alt="" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form className="row">
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="firstname">Company Name</label>
                                    <input type="text" id="companyname" name="companyname" value={Clients.companyName} className="form-control" placeholder="Company  Name" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="companylogo">Company Logo</label>
                                    <img src={Clients.companyLogo} width={200} className='rounded-circle' alt="User Avatar" />
                                    <input type="file" id="companylogo" name="companylogo" className="form-control" readOnly />
                                </div>
                                <div className="mb-3 col-12 col-md-6">
                                    <label className="form-label" htmlFor="staus">Status</label>
                                    <input type="text" id="status" name="status" value={Clients.status} className="form-control" placeholder="status" readOnly />
                                </div>

                                <div className="col-12">
                                    <Link to={'/admin/dashboard/clients'} className="btn btn-primary">Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ClientForm
