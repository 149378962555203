import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import thankYouImage from '../../assets/images/thankyou.png';


const ThankYou = () => {
    return (
        <>
            <Header />
            <div>
                <section className="mt-5 pb-lg-14 pb-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 offset-xl-3 col-md-12 col-12">
                                <div className="text-center mb-lg-10 mb-6">
                                    <h2 className="h1 fw-bold">Thank You from <u className="text-warning"><span className="text-primary">MindScape Technologies & Team</span></u></h2>
                                    <p className="lead mb-0">We sincerely appreciate your valuable feedback. Your insights are incredibly important to us and will help us enhance our services. </p>
                                </div>
                            </div>
                        </div>
                        <div className=" row align-items-center ">
                            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                                <div className="mb-6 mb-lg-0">
                                    <div className="mb-2">
                                        <img src={thankYouImage} alt="certificate" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 offset-xl-1  col-lg-6 col-md-12 col-12">
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <div className="mb-4 mb-xl-6">
                                            <div className="mb-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--geeks-primary)" className="bi bi-trophy" viewBox="0 0 16 16">
                                                    <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"></path>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4>Innovative Technology Solutions</h4>
                                                <p>Stay Ahead with Cutting-Edge Tech! Discover how our AI-driven and cloud-based IT solutions can streamline your business operations and drive growth. Learn more about how we can tailor these solutions to fit your unique needs. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-lg-6 mb-4">
                                            <div className="mb-4">
                                            <i class="bi bi-person-plus-fill text-primary" style={{fontSize: '20px'}}></i>
                                            </div>
                                            <div>
                                                <h4>Client Success Stories</h4>
                                                <p>Real Results, Real Clients. See how we've helped companies just like yours achieve their goals with our expert IT services. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-4 mb-md-0">
                                            <div className="mb-4">
                                            <i class="bi bi-eye-fill text-primary" style={{fontSize: '20px'}}></i>
                                            </div>
                                            <div>
                                                <h4>Our Vision</h4>
                                                <p>Building a Smarter Future. We envision a world where technology drives innovation, and businesses leverage it to reach their full potential. Our goal is to lead the way in creating smart, sustainable, and impactful IT solutions that benefit both our clients and the global community.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <div className="mb-4">
                                            <i class="bi bi-bullseye text-primary" style={{fontSize: '20px'}}></i>
                                            </div>
                                            <div>
                                                <h4>Our Mission</h4>
                                                <p>Empowering Businesses with Technology. We are committed to providing top-notch IT services that empower businesses to achieve their goals. Through continuous innovation and a customer-centric approach, we strive to deliver solutions that are efficient, scalable, and aligned with our clients' objectives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default ThankYou
