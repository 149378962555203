import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/Home';
import JobDescription from './Components/Career/Job/JobDescription';
import Carrer from './Components/Career/career';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Web from './Components/Pages/Web';
import AppDevelopment from './Components/Pages/AppDevelopment';
import UIUX from './Components/Pages/UIUX';
import CustomazeSolution from './Components/Pages/CustomazeSolution';
import Shopify from './Components/Pages/Shopify';
import WordPress from './Components/Pages/WordPress';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import CokiesPage from './Components/Pages/CokiesPage';
import Tems from './Components/Pages/Tems';
import NotFound from './Components/NotFound/NotFound';
import CustomerReviewMindScape from './Components/Pages/CustomerReview';
import ThankYou from './Components/Pages/ThankYou';
import ViewScroll from './Components/Config/ViewScroll';
import AppRoutes from './Services/AppRouter';
import { useState } from 'react';

function App() {
  const [user, setAuth] = useState(!!localStorage.getItem('token'));

  return (
    <BrowserRouter>
      <ViewScroll />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/career' element={<Carrer />} />
        <Route path='/job' element={<JobDescription />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/services/Web-development/' element={<Web />} />
        <Route path='/services/App-development/' element={<AppDevelopment />} />
        <Route path='/services/ui-ux/' element={<UIUX />} />
        <Route path='/services/customization-services/' element={<CustomazeSolution />} />
        <Route path='/service/shopify-development/' element={<Shopify />} />
        <Route path='/service/wordpress-development/' element={<WordPress />} />
        <Route path='/privacy-policy/' element={<PrivacyPolicy />} />
        <Route path='/cockies-policy/' element={<CokiesPage />} />
        <Route path='/terms-policy/' element={<Tems />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/mindscapetechnologies/Customer-review' element={<CustomerReviewMindScape />} />
        <Route path='/mindscapetechnologies/thankyou' element={<ThankYou />} />

        {/* Admin Routes */}
        <Route path='/admin/*' element={<AppRoutes setAuth={setAuth} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
