import axios from 'axios';

const api = 'api/admin/';

const login = async (username, password) => {
    try {
        const api = 'api/admin/';

        const response = await axios.post(api + 'login', { username, password });
        if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
            // localStorage.setItem('token', res.data.token);
            // setAuth(true);
        }
        return response.data;
    } catch (error) {
        throw error; // Rethrow the error to be caught in the Login component
    }
};

// Logout user
const logout = () => {
    localStorage.removeItem('token');
};

// Get current user
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export default {
    getCurrentUser,
    login,
    logout,
};
