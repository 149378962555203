import React from 'react'
import Footer from '../Footer/Footer'
import Clients from '../Clients/Clients'
import Reaview from '../Review/Review'
import WorkFlow from '../WorkFlow/WorkFlow'
import Services from '../Services/Services'
import Stats from '../Stats/Stats'
import Header from '../Header/Header'
import feacture from '../../assets/images/feature-img5.png'


const CustomazeSolution = () => {
    return (
        <>
            <Header />
            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="mb-4 mb-lg-0">
                                <img src={feacture} alt="..."
                                    className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">

                            <div className="ps-lg-7">
                                <span className=" text-primary ls-md text-uppercase fw-semibold">Our Services</span>
                                <h2 className="display-4 mt-4 mb-3 fw-bold">Customization Service</h2>
                                <h3>Recognizing each client's unique needs and preferences, we deliver personalized solutions that exceed expectations. From initial consultation to final delivery, our team collaborates with you to ensure every detail reflects your personal style and requirements. </h3>
                                <div className="mt-5 row">

                                    <div className="col">
                                        <ul className="list-unstyled fs-4 fw-medium">
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>UX/UI Audit & Improvements</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Mobile App Customization</li>
                                            <li className="mb-2 d-flex"><i className="bi bi-check2-circle text-success me-2"></i>Web app Feature implementation</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Stats />
            <Services />
            <WorkFlow />
            <Reaview />

            <Clients />
            <Footer />
        </>
    )
}

export default CustomazeSolution
