import React from 'react'
import verify from '../../assets/images/checked-mark.svg'
import avtar from '../../assets/images/avatar-1.jpg'

const Reaview = () => {
    return (
        <>

            {/* <div className="container">
                <div className="row text-center">
                    <div className="col-md-12 px-lg-10 mb-8 mt-6">
                        <span className="text-uppercase text-primary fw-semibold ls-md">Customer Review</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-lg-flex">
                                    <div className="position-relative">
                                        <img src={avtar} alt="" className="rounded-circle avatar-xl mb-3 mb-lg-0" />
                                        <a href="#" className="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" title="Verified">
                                            <img src={verify} alt="" height="30" width="30" />
                                        </a>
                                    </div>
                                    <div className="ms-lg-4">
                                        <h4 className="mb-0">Jenny Wilson</h4>
                                        <p className="mb-0 fs-6">Front-end Developer, Designer</p>
                                        <p className="fs-6 mb-1 text-warning"><span>4.5</span><span className="bi bi-star-half text-warning me-2"></span>Customer Rating</p>
                                        <p>I started my development and digital career studying digital design. After taking a couple of programming classes, I realized that coding is what I wanted to be doing, so I started learning by myself.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-lg-flex">
                                    <div className="position-relative">
                                        <img src={avtar} alt="" className="rounded-circle avatar-xl mb-3 mb-lg-0" />
                                        <a href="#" className="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" title="Verified">
                                            <img src={verify} alt="" height="30" width="30" />
                                        </a>
                                    </div>
                                    <div className="ms-lg-4">
                                        <h4 className="mb-0">Jenny Wilson</h4>
                                        <p className="mb-0 fs-6">Front-end Developer, Designer</p>
                                        <p className="fs-6 mb-1 text-warning"><span>4.5</span><span className="bi bi-star-half text-warning me-2"></span>Customer Rating</p>
                                        <p>I started my development and digital career studying digital design. After taking a couple of programming classes, I realized that coding is what I wanted to be doing, so I started learning by myself.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-lg-flex">
                                    <div className="position-relative">
                                        <img src={avtar} alt="" className="rounded-circle avatar-xl mb-3 mb-lg-0" />
                                        <a href="#" className="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" title="Verified">
                                            <img src={verify} alt="" height="30" width="30" />
                                        </a>
                                    </div>
                                    <div className="ms-lg-4">
                                        <h4 className="mb-0">Jenny Wilson</h4>
                                        <p className="mb-0 fs-6">Front-end Developer, Designer</p>
                                        <p className="fs-6 mb-1 text-warning"><span>4.5</span><span className="bi bi-star-half text-warning me-2"></span>Customer Rating</p>
                                        <p>I started my development and digital career studying digital design. After taking a couple of programming classes, I realized that coding is what I wanted to be doing, so I started learning by myself.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



        </>
    )
}

export default Reaview
