import React from 'react';
import girlImage from '../../assets/images/girl-image.png'
import frame1 from '../../assets/images/frame-1.svg'
import frame2 from '../../assets/images/frame-2.svg'
import sound from '../../assets/images/sound.svg'
import target from '../../assets/images/target.svg'
import trouphy from '../../assets/images/trophy.svg'

import tham from '../../assets/images/bg-thumb.svg'
import { Link } from 'react-router-dom';


const Hero = () => {
    return (
        <>
            {/* Hero Section Start */}
            <section className="py-lg-16 py-8">
                <div className="container">

                    <div className="row align-items-center">

                        <div className="col-lg-6 mb-6 mb-lg-0">
                            <div className="">

                                <h5 className="text-dark mb-4"><i className="bi bi-check2-circle icon-xxs icon-shape bg-light-success text-success rounded-circle me-2"></i>Skills for Success, Tech for Tomorrow.</h5>

                                <h1 className="display-3 fw-bold mb-3">Ready To Build Your Dream Project</h1>

                                <p className="pe-lg-10 mb-5">We are a digital agency that helps brands to achieve their business outcomes. We see technology as a tool to create amazing things.</p>

                                <Link to={'/contact'} className="btn btn-primary">Get Started</Link>

                                <a className="popup-youtube fs-4 text-inherit ms-3"><i className="bi bi-star-half me-2"></i>120+ Family</a>


                            </div>
                        </div>

                        <div className="col-lg-6 d-flex justify-content-center">

                            <div className="position-relative">
                                <img src={tham} alt="" className=" " />
                                <img src={girlImage} alt="" className=" position-absolute end-0 bottom-0" />
                                <img src={frame1} alt="" className=" position-absolute top-0 ms-lg-n10 ms-n19" />
                                <img src={frame2} alt="" className=" position-absolute bottom-0 start-0 ms-lg-n14 ms-n6 mb-n7" />
                                <img src={target} alt="" className=" position-absolute bottom-0 mb-10 ms-n10 ms-lg-n1 " />
                                <img src={sound} alt="" className=" position-absolute top-0  start-0 mt-18 ms-lg-n19 ms-n8" />
                                <img src={trouphy} alt="" className=" position-absolute top-0  start-0 ms-lg-n14 ms-n5" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero Section End */}
        </>
    );
}

export default Hero;
